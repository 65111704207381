import gql from "graphql-tag";

export default gql`
  query getUserById( $id: String )  {
    getUserById( id: $id ) {
      id
      fullName
      username
      employeeNumber
      role
      email
    }
  }
`;