import { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";

import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";
import {
  usePropertiesValuesEditMutation,
  usePropertiesValuesQuery,
} from "../../Generated/graphql";

const PropertiesValuesEdit = () => {
  const [value, setValue] = useState("");

  const match = useRouteMatch<{ propertyId: string; id: string }>();

  const [updatePropertyValue] = usePropertiesValuesEditMutation();

  const id = match.params.id;
  const propertyId = match.params.propertyId;

  const history = useHistory();
  const query = usePropertiesValuesQuery({
    variables: {
      id: propertyId,
    },
  });

  const propertyValue = useMemo(() => {
    return query.data?.property.values.find((v) => v.id === id);
  }, [query.data, id]);

  const isValid = useMemo(() => {
    return value.length >= 3;
  }, [value]);

  const onSave = () => {
    updatePropertyValue({
      variables: {
        id,
        value,
      },
    }).then(() => {
      history.goBack();
    });
  };

  // Update state when user is loaded
  useEffect(() => {
    if (!query.data) {
      return;
    }

    setValue(propertyValue?.value ?? "");
  }, [propertyValue, query.data]);

  if (query.loading) return <div>Loading...</div>;

  if (query.error) return <div>{query.error.message}</div>;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
      path: "/settings/properties",
    },
    {
      name: query.data?.property.label ?? "",
      path: `/settings/properties/${propertyId}/values`,
    },
    {
      name: propertyValue?.value ?? "",
    },
  ];

  return (
    <SettingsForm
      onSave={onSave}
      breadcrumbs={breadcrumbs}
      isButtonActive={isValid}
      theme="slim"
    >
      <div>
        <FormElement label="Navn">
          <TextInput
            value={value}
            change={(e: any) => {
              setValue(e.target.value);
            }}
            placeholder="Navn"
          />
        </FormElement>
      </div>
    </SettingsForm>
  );
};

const routed = withRouter(PropertiesValuesEdit);

export { routed as PropertiesValuesEdit };
