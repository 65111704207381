import { User } from "../../Components/User/User";
import { useUsersQuery } from "../../Generated/graphql";
import { useHistory } from "react-router-dom";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";

export const Users = () => {
  const history = useHistory();
  const { data: usersData } = useUsersQuery();

  const handleOnSave = () => {
    history.push("users/new");
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Brugere",
    },
  ];

  return (
    <SettingsForm
      breadcrumbs={breadcrumbs}
      buttonLabel="Opret bruger"
      onSave={handleOnSave}
    >
      {usersData?.users.map((user) => (
        <User user={user} key={user.id} />
      ))}
    </SettingsForm>
  );
};
