import classNames from "classnames";
import { FC } from "react";
import "./Modal.css";

type ModalProps = {
  primaryButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
  children?: React.ReactNode;
  className?: string;
};

export const Modal: FC<ModalProps> = ({
  primaryButton,
  secondaryButton,
  children,
  className,
}) => {
  return (
    <div className="Modal">
      <div className="Modal__inner">
        <div className={classNames("Modal__content", className)}>
          {children}
        </div>
        <div className="Modal__buttons">
          {secondaryButton !== undefined && (
            <div
              className="Modal__button Modal__button--secondary"
              onClick={secondaryButton.onClick}
            >
              <div className="Modal__button-text">{secondaryButton.text}</div>
            </div>
          )}
          {primaryButton !== undefined && (
            <div
              className="Modal__button Modal__button--primary"
              onClick={primaryButton.onClick}
            >
              <div className="Modal__button-text">{primaryButton.text}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
