import {
  QueryProduct,
  QueryProductVariant,
} from "../Containers/Overview/QueryProduct.type";
import { get, isString } from "lodash";

/**
 * Represents the product status from ERP
 */
export type Status = {
  statusCode: string;
  statusText: string;
};

/**
 * From a product, get a validated and typed representation of product status
 */
export const getProductStatus = (
  product: QueryProduct | QueryProductVariant
): Status | null => {
  if (!product.productData) {
    return null;
  }

  // Try to get the status from the product data
  const statusCode = get(product.productData, "status.statusCode", null);
  const statusText = get(product.productData, "status.statusText", null);

  // Return null if no valid status can be created
  if (!isString(statusCode) || !isString(statusText)) {
    return null;
  }

  return {
    statusCode,
    statusText,
  };
};
