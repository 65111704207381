import gql from "graphql-tag";

export default gql`
  mutation updateUser( $id: String, $username: String, $employeeNumber: String, $email: String, $role: String, $fullName: String ) {
    updateUser( id: $id, username: $username, employeeNumber: $employeeNumber, email: $email, role: $role, fullName: $fullName ) {
      id
      username
      employeeNumber
      email
      role
      fullName
    }
  }
`