import { ValidIcons } from "./Components/Icon/Icon-list";

const expectString = (msg: string, value: unknown): string => {
  if (typeof value !== "string") {
    throw new Error(`${msg}: Expected string`);
  }
  return value;
};

export const config = {
  logo: {
    appLogoName: "logo" as ValidIcons,
    appLogoWidth: 34,
  },
  company: {
    name: "Baden-Jensen A/S",
  },
  api: {
    endpoint: expectString("API Endpoint", process.env.REACT_APP_API),
  },
  aws: {
    bucketName: expectString(
      "AWS bucket name",
      process.env.REACT_APP_AWS_BUCKET_NAME
    ),
  },
  fieldSettings: {
    productNameField: "name",
    productImageField: "billeder",
    productCategoryField: "productLevelB",
    /** Used for extra context in variant selection components */
    productDescriptionField: "description",
  },
};
