import { FC } from "react";
import { useHistory } from "react-router-dom";

import {
  User as UserType,
  UsersDocument,
  useDeleteUserMutation,
} from "../../Generated/graphql";
import { SettingsListItem } from "../SettingsListItem/SettingsListItem";

type Props = {
  user: UserType;
};

export const User: FC<Props> = ({ user }) => {
  const role = user.role === "ADMIN" ? "Administrator" : "Bruger";
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [{ query: UsersDocument }],
  });
  const history = useHistory();

  const handleDeleteUser = () => {
    if (user.username === "SuperAdmin") {
      alert("Denne bruger kan ikke slettes");
      return;
    }

    deleteUser({ variables: { id: user.id } });
  };

  const handleEditClick = () => {
    history.push("users/" + user.id);
  };

  return (
    <SettingsListItem
      deleteClick={handleDeleteUser}
      iconClick={handleEditClick}
      name={user.fullName ?? ""}
      label={role ?? ""}
    />
  );
};
