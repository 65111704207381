import { FC, PropsWithChildren } from "react";
import { Button } from "../Button/Button";

import "./SettingsForm.css";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

export type Breadcrumb = {
  name: string;
  path?: string;
};

type Theme = "normal" | "slim";

type Props = {
  onSave: () => void;
  isButtonActive?: boolean;
  breadcrumbs: Breadcrumb[];
  buttonLabel?: string;
  theme?: Theme;
};

export const SettingsForm: FC<PropsWithChildren<Props>> = ({
  children,
  onSave,
  breadcrumbs,
  isButtonActive = true,
  buttonLabel = "Gem",
  theme = "normal",
}) => {
  return (
    <div
      className={classNames({
        SettingsForm: true,
        "SettingsForm--slim": theme === "slim",
      })}
    >
      <div className="SettingsForm__content">
        <div className="SettingsForm__title">
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadCrumb
              breadcrumb={breadcrumb}
              index={index}
              key={breadcrumb.name}
            />
          ))}
        </div>
        <div className="SettingsForm__form">{children}</div>
        <div className="SettingsForm__save">
          <div className="SettingsForm__save-button">
            <Button
              buttonStyling="save"
              label={buttonLabel}
              click={() => isButtonActive && onSave()}
              active={isButtonActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BreadCrumb: FC<{ breadcrumb: Breadcrumb; index: number }> = ({
  breadcrumb,
  index,
}) => {
  const history = useHistory();

  const maybeDash = index === 0 ? <></> : <>&nbsp;/&nbsp;</>;

  if (breadcrumb.path !== undefined) {
    return (
      <div
        className="SettingsForm__title-static"
        onClick={() => history.push(breadcrumb.path ?? "")}
      >
        {maybeDash}
        {breadcrumb.name}
      </div>
    );
  }

  return (
    <div className="SettingsForm__title-fluid">
      {maybeDash}
      {breadcrumb.name}
    </div>
  );
};
