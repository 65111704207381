import { FC } from "react";
import { Icon } from "../../Components/Icon/Icon";

type StateButtonProps = {
  click: () => void;
  state: boolean;
};

export const StateButton: FC<StateButtonProps> = ({ click, state }) => {
  return (
    <div className="Edit__actions-state u-cursor-pointer" onClick={click}>
      <Icon
        name={state ? "visibilityVisible" : "visibilityHidden"}
        width={40}
      />
      <div className="Edit__actions-stateText">
        {state ? "Aktiveret i shop" : "Skjult i shop"}
      </div>
    </div>
  );
};
