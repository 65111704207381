import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../Modal/Modal";
import classNames from "classnames";

import "./VariantSelector.css";
import {
  GroupProductType,
  useVariantSelectorQuery,
} from "../../Generated/graphql";
import { config } from "../../config";
import { Loading } from "../Loading/Loading";
import { formatProductDescription } from "../../Utils/formatProductDescription";
import { Icon } from "../Icon/Icon";

type Props = {
  category?: string;
  onSelectVariants: (variantIds: string[]) => void;
  onCancel: () => void;
};

export const VariantSelector: FC<Props> = ({
  category,
  onCancel,
  onSelectVariants,
}) => {
  const { data, loading } = useVariantSelectorQuery({
    variables: {
      categories: category ? [category] : undefined,
    },
  });

  const [filterText, setFilterText] = useState("");

  const filterInputRef = useRef<HTMLInputElement>(null);

  /*
   * Transform the data to the wanted structure
   */
  const variants = useMemo(() => {
    if (!data || loading) return [];

    const products = data.products
      // Only single products are available as variants
      .filter((product) => product.type === GroupProductType.Single)
      // Map to the wanted structure
      .map((product) => ({
        id: product.id,
        name:
          product.productData[config.fieldSettings.productNameField] ??
          "Intet navn",
        description: formatProductDescription(product),
      }));

    // Sort products by name
    products.sort((a, b) => a.name.localeCompare(b.name));

    return products;
  }, [data, loading]);

  // Filter by the typed text
  const filteredVariants = useMemo(() => {
    return variants.filter((variant) =>
      variant.name.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [variants, filterText]);

  const [selectedVariants, setSelectedVariants] = useState<string[]>([]);

  /** Focus the filter input when opening */
  useEffect(() => {
    filterInputRef.current?.focus();
  }, [filterInputRef]);

  /**
   * Toggle a variant
   */
  const handleToggleVariant = (variantId: string) => {
    if (isSelected(variantId)) {
      setSelectedVariants(selectedVariants.filter((id) => id !== variantId));
    } else {
      setSelectedVariants([...selectedVariants, variantId]);
    }
  };

  const handleResetFilter = () => {
    setFilterText("");
  };

  /**
   * Determine if a variant has been selected
   */
  const isSelected = useCallback(
    (variantId: string) => {
      return selectedVariants.includes(variantId);
    },
    [selectedVariants]
  );

  const selectedCount = useMemo(() => {
    return selectedVariants.length;
  }, [selectedVariants]);

  const ModalPrimaryButton = useMemo(() => {
    return {
      text:
        selectedVariants.length <= 1
          ? "Tilføj variant"
          : `Tilføj ${selectedVariants.length} varianter`,
      onClick: () => onSelectVariants(selectedVariants),
    };
  }, [selectedVariants, onSelectVariants]);

  const modalSecondaryButton = useMemo(() => {
    return {
      text: "Annuller",
      onClick: () => onCancel(),
    };
  }, [onCancel]);

  const showFilterResetIcon = useMemo(() => {
    return filterText.length > 0;
  }, [filterText]);

  return (
    <div className="VariantSelector">
      <Modal
        primaryButton={ModalPrimaryButton}
        secondaryButton={modalSecondaryButton}
        className="VariantSelector__Modal"
      >
        <header className="VariantSelector__Header">
          <div className="VariantSelector__Headline">
            Vælg varianter
            {selectedCount > 0 && <>&nbsp;({selectedCount})</>}
          </div>
          <div className="VariantSelector__Filter">
            <input
              type="text"
              placeholder="Søg..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              ref={filterInputRef}
            />
            {showFilterResetIcon && (
              <div
                className="VariantSelector__FilterIcon"
                onClick={handleResetFilter}
              >
                <Icon name="resetNormal" width={15} />
              </div>
            )}
          </div>
        </header>
        {loading === true && (
          <div className="VariantSelector__Loading">
            <Loading />
          </div>
        )}
        {loading === false && (
          <div className="VariantSelector__Variants">
            {filteredVariants.map((variant, i) => (
              <div
                className={classNames("VariantSelector__Variant", {
                  "VariantSelector__Variant--selected": isSelected(variant.id),
                })}
                key={i}
                onClick={() => handleToggleVariant(variant.id)}
              >
                <div className="VariantSelector__VariantCheckbox">
                  <input
                    type="checkbox"
                    checked={isSelected(variant.id)}
                    readOnly
                  />
                </div>
                <div className="VariantSelector__VariantDetails">
                  <div className="VariantSelector__VariantName">
                    {variant.name}
                  </div>
                  <div className="VariantSelector__VariantDescription">
                    {variant.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};
