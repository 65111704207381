import React from 'react';

import './SyncPlanItem.css';
import { Select, SelectOption } from '../../Select/Select';
import { Icon } from '../../Icon/Icon';

export const SyncPlanItem = ({
  time,
  index,
  dayChangeHandler,
  timeChangeHandler,
  deleteTask
}) => {
  return (
    <div className="SyncPlan__sync" key={time.id}>
      <div className="SyncPlan__sync-day">
        <Select
          change={v => dayChangeHandler(v, index)}
          value={time.day}
          type="sync"
        >
          {options.map(({ label, value }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </div>
      <div className="SyncPlan__sync-time">
        <Select
          change={v => timeChangeHandler(v, index)}
          value={time.time}
          type="sync"
        >
          {timeOptions.map(({ label, value }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </div>
      <div className="SyncPlan__delete" onClick={() => deleteTask(time.id)}>
        <Icon name="deleteNormal" width={9} />
      </div>
    </div>
  );
};

const timeOptions = [
  {
    label: '00:00',
    value: 0
  },
  {
    label: '01:00',
    value: 1
  },
  {
    label: '02:00',
    value: 2
  },
  {
    label: '03:00',
    value: 3
  },
  {
    label: '04:00',
    value: 4
  },
  {
    label: '05:00',
    value: 5
  },
  {
    label: '06:00',
    value: 6
  },
  {
    label: '07:00',
    value: 7
  },
  {
    label: '08:00',
    value: 8
  },
  {
    label: '09:00',
    value: 9
  }
];

const options = [
  {
    label: 'Mandag',
    value: 1
  },
  {
    label: 'Tirsdag',
    value: 2
  },
  {
    label: 'Onsdag',
    value: 3
  },
  {
    label: 'Torsdag',
    value: 4
  },
  {
    label: 'Fredag',
    value: 5
  },
  {
    label: 'Lørdag',
    value: 6
  },
  {
    label: 'Søndag',
    value: 7
  }
];
