import { useEffect, useMemo, useState } from "react";
import { config } from "../../config";

type UseEditProductOrGroupArgs = {
  initialCustomData: Record<string, any> | null;
  initialProductData: Record<string, any> | null;
  initialShowInShop: boolean | null;
};

export const useEditProductOrGroup = ({
  initialCustomData,
  initialProductData,
  initialShowInShop,
}: UseEditProductOrGroupArgs) => {
  // Set a local version of the changed data
  const [customData, setCustomData] = useState<Record<string, any> | null>(
    null
  );

  const [showInShop, setShowInShop] = useState<boolean | null>(null);

  const handleChangeShowInShop = () => {
    setShowInShop((prev) => !prev ?? true);
  };

  // Determine if customData has changed
  const hasCustomDataChanged = useMemo(() => {
    const customDataHasBeenFetched = customData !== null;
    const customDataHasChanged = customData !== initialCustomData;

    return customDataHasBeenFetched && customDataHasChanged;
  }, [customData, initialCustomData]);

  // Determine if showInShop has changed
  const hasShowInShopChanged = useMemo(() => {
    const showInShopHasBeenFetched = showInShop !== null;
    const showInShopHasChanged = showInShop !== initialShowInShop;

    return showInShopHasBeenFetched && showInShopHasChanged;
  }, [showInShop, initialShowInShop]);

  // Determine if the data has changed
  const isDirty = useMemo(() => {
    const hasValues = customData !== null;

    return hasValues && (hasCustomDataChanged || hasShowInShopChanged);
  }, [customData, hasCustomDataChanged, hasShowInShopChanged]);

  // Overwrite local data with server data, eg. on initial load and when refetching
  useEffect(() => {
    // Don't update with undefined data
    if (initialCustomData === null || initialShowInShop === null) {
      return;
    }

    setCustomData(initialCustomData);
    setShowInShop(initialShowInShop);
  }, [initialCustomData, initialShowInShop, setCustomData, setShowInShop]);

  // Get the category according to the configured field
  const category = useMemo(() => {
    const category =
      initialCustomData?.[config.fieldSettings.productCategoryField] ??
      initialProductData?.[config.fieldSettings.productCategoryField];

    return typeof category === "string" ? category : null;
  }, [initialCustomData, initialProductData]);

  return {
    customData,
    setCustomData,
    showInShop,
    handleChangeShowInShop,
    category,
    isDirty,
    hasShowInShopChanged,
    hasCustomDataChanged,
  };
};
