import React, { FC, PropsWithChildren } from "react";

import "./FormElement.css";

type Props = {
  label: string;
};

export const FormElement: FC<PropsWithChildren<Props>> = ({
  label,
  children,
}) => {
  return (
    <div className="FormElement">
      <div className="FormElement__label">
        <div className="FormElement__label-text">{label}</div>
      </div>
      <div className="FormElement__element">{children}</div>
    </div>
  );
};
