import gql from "graphql-tag";

export default gql`
  mutation createUser ($username: String!, $fullName: String!, $password: String!, $email: String!, $employeeNumber: String, $role: String!) {
  createUser(username: $username, fullName: $fullName, password: $password, email: $email, employeeNumber: $employeeNumber, role: $role) {
    id
    username
    password
    email
    employeeNumber
    
  }
}
`;
