import classNames from "classnames";
import { FC } from "react";
import "./ModalLarge.css";
import { Button } from "../Button/Button";

type ModalLargeProps = {
  primaryButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
  children?: React.ReactNode;
  className?: string;
};

export const ModalLarge: FC<ModalLargeProps> = ({
  primaryButton,
  secondaryButton,
  children,
  className,
}) => {
  const showButtons =
    secondaryButton !== undefined || primaryButton !== undefined;

  return (
    <div className="ModalLarge">
      <div className="ModalLarge__inner">
        <div className={classNames("ModalLarge__content", className)}>
          {children}
        </div>
        {showButtons && (
          <div className="ModalLarge__buttons">
            {secondaryButton !== undefined && (
              <div className="ModalLarge__button">
                <Button
                  click={secondaryButton.onClick}
                  label={secondaryButton.text}
                  buttonStyling="secondary"
                />
              </div>
            )}
            {primaryButton !== undefined && (
              <div className="ModalLarge__button">
                <Button
                  click={primaryButton.onClick}
                  label={primaryButton.text}
                  buttonStyling="primary"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
