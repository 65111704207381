import { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { EditProduct } from "./EditProduct";
import { EditGroup } from "./EditGroup";
import { EditCategory } from "./EditCategory";
import { editNavigationVar } from "../../App";
import { useReactiveVar } from "@apollo/client";

export const Edit: FC<{}> = () => {
  const { url } = useRouteMatch();

  const selections = useReactiveVar(editNavigationVar);

  return (
    <div className="Edit">
      <div className="Edit__category">
        {selections && (
          <EditCategory
            categoryId={selections.selectedCategory}
            selectedProductId={selections.selectedProductNumber}
          />
        )}
      </div>

      <Switch>
        <Route
          path={`${url}/product/:productid`}
          render={({
            match: {
              params: { productid },
            },
          }) => <EditProduct productId={productid} />}
        />
        <Route
          path={`${url}/group/:groupId`}
          render={({
            match: {
              params: { groupId },
            },
          }) => <EditGroup groupId={groupId} />}
        />
      </Switch>
    </div>
  );
};
