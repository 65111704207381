import { FC, useMemo } from "react";
import { DragAndDropList } from "../DragAndDropList/DragAndDropList";
import { EditSpecification, Specification } from "./EditSpecification";
import { Button } from "../Button/Button";
import { ListItemNull } from "../ListItemNull/ListItemNull";

type Props = {
  value: Specification[];
  onChange: (newValue: Specification[]) => void;
  /**
   * Provide an async function eg. to show a confirmation modal
   * Reject the promise to cancel the delete
   * */
  onBeforeDelete?: (index: number) => Promise<void>;
  readonly?: boolean;
};

export const EditSpecifications: FC<Props> = ({
  value: items,
  onChange,
  onBeforeDelete = () => Promise.resolve(),
  readonly = false,
}) => {
  const handleUpdate = (newValue: Specification, index: number) => {
    const newItems = [...items];
    newItems[index] = newValue;
    onChange(newItems);
  };

  const handleAdd = () => {
    const newSpec = { key: "", value: "", sorting: items.length };
    const newItems = [...items, newSpec];
    onChange(newItems);
  };

  const handleSort = ({
    prevIndex,
    curIndex,
  }: {
    prevIndex: number;
    curIndex: number;
  }) => {
    const newItems = [...items];
    const [removed] = newItems.splice(prevIndex, 1);
    newItems.splice(curIndex, 0, removed);
    onChange(newItems);
  };

  const showList = useMemo(() => items.length > 0, [items.length]);

  const showNullScreen = useMemo(() => items.length === 0, [items.length]);

  const handleDelete = async (args: { index: number }) => {
    try {
      await onBeforeDelete(args.index);
    } catch (e) {
      // User cancelled delete
      return;
    }

    const newItems = [...items];
    newItems.splice(args.index, 1);
    onChange(newItems);
  };

  return (
    <div className="EditSpecifications">
      {showList && (
        <DragAndDropList
          items={items}
          onSort={handleSort}
          onDelete={handleDelete}
          readonly={readonly}
        >
          {({ item, index }, { isDragging }) => (
            <EditSpecification
              key={index}
              isDragging={isDragging}
              spec={item}
              updateInput={(newSpec) => handleUpdate(newSpec, index)}
              readonly={readonly}
            />
          )}
        </DragAndDropList>
      )}
      {showNullScreen && <ListItemNull>Ingen specifikationer</ListItemNull>}
      <div className="EditSpecifications__Add">
        <Button
          label="Tilføj"
          buttonStyling="editAdd"
          click={() => handleAdd()}
          active={!readonly}
        />
      </div>
    </div>
  );
};
