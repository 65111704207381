import { FC, useMemo } from "react";
import { CSVLink } from "react-csv";
import "./ExportProductList.css";
import { Icon } from "../../Components/Icon/Icon";
import { QueryProduct } from "./QueryProduct.type";
import { config } from "../../config";

type Props = {
  products: QueryProduct[];
};

export const ExportProductList: FC<Props> = ({ products }) => {
  const csvData = useMemo(() => {
    type Row = [number: string, name: string, description: string];

    const titleRow: Row = ["Produkt nummer", "Navn", "Beskrivelse"];

    const dataRows: Row[] = products.flatMap((product) => {
      const parentRow: Row = [
        product.productNumber ?? "Variantgruppe",
        product.name,
        product.productData?.[config.fieldSettings.productDescriptionField] ??
          "",
      ];

      const childRows: Row[] =
        product.variants?.map((variant) => [
          variant.productNumber,
          variant.name,
          product.productData?.[config.fieldSettings.productDescriptionField] ??
            "",
        ]) ?? [];

      return [parentRow, ...childRows];
    });

    return [titleRow, ...dataRows];
  }, [products]);

  return (
    <div className="ExportProductList">
      {csvData.length > 0 && (
        <CSVLink data={csvData} filename={"bj-produkt-oversigt.csv"}>
          <Icon
            name="iconDownload"
            width={16}
            height={19}
            className="ExportProductList__icon"
          />
        </CSVLink>
      )}
    </div>
  );
};
