import React, { FC } from "react";

import "./FailedUpload.css";
import { Icon } from "../Icon/Icon";

type Props = {
  name: string;
  index: number;
};

export const FailedUpload: FC<Props> = ({ name, index }) => {
  return (
    <div
      className="FailedUpload"
      style={index === 0 ? { borderTop: "1px solid #d9d9d9" } : {}}
    >
      <Icon name="notSaved" width={19} />
      <div className="FailedUpload__name">{name}</div>
    </div>
  );
};
