import { useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import "./ImportProductData.css";
import updateProduct from "../Overview/updateProductShowInShop.mutation";
import { Button } from "../../Components/Button/Button";
import { SpinAndDone } from "../../Components/SpinAndDone/SpinAndDone";
import { useMutation } from "@apollo/client";
import classNames from "classnames";

export const ImportProductData = () => {
  const [mutationUpdate] = useMutation(updateProduct);
  const [isActive, setIsActive] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const fileRef = useRef<HTMLInputElement | null>(null);

  const updateProductsFromExcel = () => {
    const myFile = fileRef.current?.files?.[0];

    if (!myFile) {
      return;
    }

    readXlsxFile(myFile)
      .then((rows) => {
        const nameIndex = rows[0].findIndex((x) => x === "Titel");
        const kortIndex = rows[0].findIndex((x) => x === "Kortbeskrivelse");
        const langIndex = rows[0].findIndex((x) => x === "Langbeskrivelse");
        const sizeIndex = rows[0].findIndex((x) => x === "Størrelse");

        return rows.map((row, index) => {
          if (index !== 0) {
            const product = {
              prodNo: row[0],
              customData: {
                name: row[nameIndex],
                kortbeskrivelse: row[kortIndex],
                langbeskrivelse: row[langIndex] ? row[langIndex] : "",
                størrelse: row[sizeIndex],
              },
            };
            return mutationUpdate({ variables: product });
          }
          return null;
        });
      })
      .then(() => {
        setUploaded(true);
      });
  };

  return (
    <div className="ImportProductData">
      <div className="ImportProductData__title">
        Importer produktdata fra Excel
      </div>
      <div className="ImportProductData__content">
        <div className="ImportProductData__fileInput">
          <div className="ImportProductData__fileInput-step">
            <div className="ImportProductData__fileInput-step-one">Step 1:</div>
            <div className="ImportProductData__fileInput-step-text">
              Vælg filen der skal importeres fra
            </div>
          </div>
          <div className="ImportProductData__fileInput-content">
            <div className="ImportProductData__fileInput-text">
              Filtype: Excel-fil (.xlsx)
            </div>

            <div className="ImportProductData__fileInput-input">
              <input
                hidden
                type="file"
                onChange={() => {
                  setIsActive(true);
                  setUploaded(false);
                }}
                ref={fileRef}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <Button
                label="Vælg fil"
                click={() => fileRef.current?.click()}
                buttonStyling="save"
              />
              <div className="ImportProductData__file-name">
                {fileRef.current?.files?.[0]
                  ? fileRef.current?.files?.[0].name
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("ImportProductData__updateButton", {
            "ImportProductData__updateButton--active": isActive,
          })}
        >
          <div className="ImportProductData__updateButton-step">
            <div className="ImportProductData__updateButton-step-one">
              Step 2:
            </div>
            <div className="ImportProductData__updateButton-step-text">
              Indlæs produktdata fra Excel-filen
            </div>
          </div>
          <div className="ImportProductData__updateButton-container">
            {!uploaded ? (
              <div className="ImportProductData__updateButton--button">
                <Button
                  label="Indlæs data"
                  buttonStyling="save"
                  click={updateProductsFromExcel}
                />
              </div>
            ) : (
              <SpinAndDone />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
