import { FC, useMemo, useState } from "react";
import { Select, SelectOption } from "../Select/Select";
import { Modal } from "../Modal/Modal";
import { TextInput } from "../TextInput/TextInput";
import { useDropdownMetaFieldQuery } from "../../Generated/graphql";

import "./DropdownEditable.css";

type newValueState =
  | {
      state: "none";
    }
  | {
      state: "modalShown";
      newValue: string;
    }
  | {
      state: "newValueAdded";
      newValue: string;
    };

type Props = {
  metadataId: string;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
};

export const DropdownEditable: FC<Props> = ({
  metadataId,
  value,
  disabled,
  onChange,
}) => {
  const { loading, data } = useDropdownMetaFieldQuery({
    variables: {
      metadataId,
    },
  });

  const [newValueState, setNewValueState] = useState<newValueState>({
    state: "none",
  });

  const handleChange = (value: string) => {
    if (value === "new") {
      setNewValueState({
        state: "modalShown",
        newValue: "",
      });
    }

    onChange(value);
  };

  const handleAddValue = () => {
    if (newValueState.state !== "modalShown") {
      return;
    }

    setNewValueState({
      state: "newValueAdded",
      newValue: newValueState.newValue,
    });

    onChange(newValueState.newValue);
  };

  const handleChangeValue = (value: string) => {
    if (newValueState.state !== "modalShown") {
      return;
    }

    setNewValueState({
      state: "modalShown",
      newValue: value,
    });
  };

  const handleCancel = () => {
    setNewValueState({
      state: "none",
    });
  };

  const primaryButton = {
    text: "Tilføj",
    onClick: () => handleAddValue(),
  };

  const secondaryButton = {
    text: "Fortryd",
    onClick: () => handleCancel(),
  };

  const metadataValues = useMemo(() => {
    if (!data) {
      return [];
    }

    const newValue =
      newValueState.state === "newValueAdded" ? [newValueState.newValue] : [];

    return [...data.metadataValues, ...newValue];
  }, [data, newValueState]);

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && data && (
        <>
          <Select
            change={handleChange}
            value={value ?? ""}
            type="default"
            disabled={disabled}
            showFilter={true}
          >
            {metadataValues.map((value) => (
              <SelectOption key={value} value={value}>
                {value}
              </SelectOption>
            ))}
            <SelectOption value="new">Tilføj ny</SelectOption>
          </Select>
          {newValueState.state === "modalShown" && (
            <Modal
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
              className="DropdownEditableValuePopup"
            >
              <div className="DropdownEditableValuePopup__Headline">
                Tilføj ny værdi
              </div>
              <div className="DropdownEditableValuePopup__Body">
                <TextInput
                  value={newValueState.newValue}
                  change={(e) => handleChangeValue(e.target.value)}
                  placeholder="Indtast værdi"
                  autofocus
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
