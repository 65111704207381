import {
  useDeletePropertyValueMutation,
  usePropertiesValuesQuery,
} from "../../Generated/graphql";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SettingsListItem } from "../../Components/SettingsListItem/SettingsListItem";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";

export const PropertiesValues = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();

  const { data: propertiesData, refetch } = usePropertiesValuesQuery({
    variables: { id: match.params.id },
    fetchPolicy: "network-only",
  });
  const [deletePropertyValue] = useDeletePropertyValueMutation();

  const handleDeletePropertyValue = async (id: string) => {
    await deletePropertyValue({
      variables: {
        id,
      },
    });

    refetch();
  };

  const handleCreateNewClick = () => {
    history.push(
      `/settings/properties/${propertiesData?.property.id}/values/new`
    );
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
      path: "/settings/properties",
    },
    {
      name: propertiesData?.property.label ?? "",
    },
  ];

  const hasValues = propertiesData && propertiesData.property.values.length > 0;

  return (
    <SettingsForm
      onSave={handleCreateNewClick}
      breadcrumbs={breadcrumbs}
      buttonLabel="Opret ny"
      theme="slim"
    >
      <div className="PropertiesValues__PropertiesValues">
        {hasValues === false && <SettingsListItem name="Ingen værdier" />}
        {propertiesData?.property.values.map((value) => (
          <SettingsListItem
            name={value.value}
            deleteClick={() => handleDeletePropertyValue(value.id)}
            iconClick={() =>
              history.push(
                `/settings/properties/${propertiesData.property.id}/values/${value.id}`
              )
            }
            key={value.id}
          />
        ))}
      </div>
    </SettingsForm>
  );
};
