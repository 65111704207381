import { ReactComponent as logo } from "../../../src/Assets/logo.svg";
import { ReactComponent as home } from "../../../src/Assets/icon-back/inactive.svg";
import { ReactComponent as homeActive } from "../../../src/Assets/icon-back/active.svg";
import { ReactComponent as settings } from "../../../src/Assets/icon-settings.svg";
import { ReactComponent as logout } from "../../../src/Assets/icon-logout.svg";
import { ReactComponent as syncSucces } from "../../../src/Assets/icon-status/succes.svg";
import { ReactComponent as syncLoading } from "../../../src/Assets/icon-status/loading.svg";
import { ReactComponent as syncNormal } from "../../../src/Assets/icon-sync/normal.svg";
import { ReactComponent as visibilityVisible } from "../../../src/Assets/icon-visibility/visible.svg";
import { ReactComponent as visibilityHidden } from "../../../src/Assets/icon-visibility/hidden.svg";
import { ReactComponent as editNormal } from "../../../src/Assets/icon-edit/normal.svg";
import { ReactComponent as editHover } from "../../../src/Assets/icon-edit/hover.svg";
import { ReactComponent as arrowDown } from "../../../src/Assets/icon-arrow/down.svg";
import { ReactComponent as resetNormal } from "../../../src/Assets/icon-reset/normal.svg";
import { ReactComponent as resetHover } from "../../../src/Assets/icon-reset/hover.svg";
import { ReactComponent as deleteNormal } from "../../../src/Assets/icon-delete/normal.svg";
import { ReactComponent as deleteHover } from "../../../src/Assets/icon-delete/hover.svg";
import { ReactComponent as deleteIcon } from "../../../src/Assets/icon-delete.svg";
import { ReactComponent as sort } from "../../../src/Assets/icon-sort.svg";
import { ReactComponent as files } from "../../../src/Assets/icon-files.svg";
import { ReactComponent as search } from "../../../src/Assets/icon-search.svg";
import { ReactComponent as uploadInactive } from "../../../src/Assets/icon-upload/blue.svg";
import { ReactComponent as uploadActive } from "../../../src/Assets/icon-upload/white.svg";
import { ReactComponent as notSaved } from "../../../src/Assets/icon-status/error.svg";
import { ReactComponent as noImageAvail } from "../../../src/Assets/icon-missing-image.svg";
import { ReactComponent as iconDownload } from "../../../src/Assets/icon-download.svg";
import { ReactComponent as iconAdd } from "../../../src/Assets/icon-add.svg";

const icons = {
  logo,
  home,
  homeActive,
  settings,
  logout,
  syncSucces,
  syncLoading,
  syncNormal,
  visibilityVisible,
  visibilityHidden,
  editNormal,
  editHover,
  arrowDown,
  resetNormal,
  resetHover,
  deleteIcon,
  deleteNormal,
  deleteHover,
  sort,
  files,
  search,
  uploadInactive,
  uploadActive,
  notSaved,
  noImageAvail,
  iconDownload,
  iconAdd,
};

export default icons;

export type ValidIcons = keyof typeof icons;
