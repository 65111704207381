import { config } from "../config";

/**
 * Format a variant description by combining product number and the configured description field
 * Return a descriptor, if product is a Variant Group
 *
 */
export const formatProductDescription = (product: {
  prodNo?: string;
  productData: any;
}) => {
  if (!product.prodNo) {
    return "Variantgruppe";
  }

  return [
    product.prodNo,
    product.productData?.[config.fieldSettings.productDescriptionField],
  ]
    .filter(isDefinedAndNonEmpty)
    .join(" - ");
};

const isDefinedAndNonEmpty = (value: any) =>
  value !== undefined &&
  value !== null &&
  (typeof value !== "string" || value.trim() !== "");
