import { isNil } from "lodash";
import { Metadata } from "../Generated/graphql";
import { Percentage } from "./Percentage";

export const calculateVariantPercentageDone = (
  metadata: Metadata[],
  variant: {
    customData: Record<string, any>;
    /** Note: The Optional should not happen, and is only here for typing ceremony unfortunately */
    productData?: Record<string, any>;
  }
): Percentage => {
  // Only track fields that are configured to be tracked
  const applicableMetadata = metadata.filter(
    ({ trackCompleteness }) => trackCompleteness
  );

  // Get the total amount of fields
  const totalFields = applicableMetadata.length;

  // Combine the productData and customData, since we only care about fields that are empty in both
  const combinedData = {
    ...variant.productData,
    ...variant.customData,
  };

  // Get the amount of fields that are done
  const amountOfFieldsDone = applicableMetadata.filter(({ productDataKey }) => {
    const productHasTheKey = productDataKey in combinedData;
    const valueIsValid = !isNil(combinedData[productDataKey]);
    return productHasTheKey && valueIsValid;
  }).length;

  // Return the percentage
  return Percentage.fromAmountOf(amountOfFieldsDone, totalFields);
};
