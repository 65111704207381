import { FC, PropsWithChildren } from "react";
import { Redirect } from "react-router-dom";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { useMeQuery } from "../../Generated/graphql";

export const Authorized: FC<PropsWithChildren> = ({ children }) => {
  // Fetch "me" to see if we are logged in, and thereby get user information
  const { data, loading, error } = useMeQuery();

  if (loading) {
    return <LoadingComponent />;
  }

  if (
    data?.me === null ||
    [
      "UNAUTHENTICATED",
      "UNAUTHORIZED",
      "INTERNAL_SERVER_ERROR",
      "NO_USER_TOKEN",
    ].includes(error?.graphQLErrors?.[0]?.extensions?.code as string)
  ) {
    return <Redirect to="/login" />;
  }

  if (!loading && data?.me) {
    return <>{children}</>;
  }

  return <div>Something went terribly wrong</div>;
};
