import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import { Select, SelectOption } from "../../Components/Select/Select";
import createUser from "../../GraphQL/Mutations/createUser";
import { useMutation } from "@apollo/client";
import { useMeQuery } from "../../Generated/graphql";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";

const UserNew = () => {
  const history = useHistory();
  const { data, loading } = useMeQuery();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [role, setRole] = useState("USER");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [makeUser] = useMutation(createUser);

  if (loading) return <div>Loading...</div>;

  function save() {
    makeUser({
      variables: {
        username,
        fullName: name,
        password,
        email,
        employeeNumber,
        role,
      },
    }).then(() => {
      history.goBack();
    });
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Brugere",
      path: "/settings/users",
    },
    {
      name: "Ny bruger",
    },
  ];

  return (
    <SettingsForm
      onSave={save}
      breadcrumbs={breadcrumbs}
      buttonLabel="Opret"
      theme="slim"
    >
      <div className="UserEdit__fullname">
        <FormElement label="Navn">
          <TextInput
            value={name}
            change={(e) => {
              setName(e.target.value);
            }}
          />
        </FormElement>
      </div>
      <div className="UserEdit__username">
        <FormElement label="Brugernavn">
          <TextInput
            value={username}
            change={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FormElement>
      </div>
      <div className="UserEdit__email">
        <FormElement label="Email">
          <TextInput
            value={email}
            change={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormElement>
      </div>
      <div className="UserEdit__password">
        <FormElement label="Password">
          <TextInput
            value={password}
            change={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormElement>
      </div>
      <div className="UserEdit__employeeNumber">
        <FormElement label="Medarbejder nummer">
          <TextInput
            value={employeeNumber}
            change={(e) => {
              setEmployeeNumber(e.target.value);
            }}
          />
        </FormElement>
      </div>
      {data?.me?.role === "ADMIN" && (
        <div className="UserEdit__role">
          <FormElement label="Rettigheder">
            {roles && (
              <Select
                change={(v) => {
                  setRole(v);
                }}
                value={roles.find((x) => x.value === role)?.value!}
                type="default"
              >
                {roles.map(({ label, value }) => (
                  <SelectOption key={value} value={value}>
                    {label}
                  </SelectOption>
                ))}
              </Select>
            )}
          </FormElement>
        </div>
      )}
    </SettingsForm>
  );
};

const routed = withRouter(UserNew);

export { routed as UserNew };

const roles = [
  {
    label: "Administrator",
    value: "ADMIN",
  },
  {
    label: "Bruger",
    value: "USER",
  },
];
