import { FC } from "react";
import "./Button.css";
import classNames from "classnames";

type ButtonStyling =
  | "default"
  | "editAdd"
  | "save"
  | "login"
  | "syncAdd"
  | "configureProperties"
  | "primary"
  | "secondary";

type Props = {
  click: (() => void) | (() => Promise<void>);
  label: string;
  buttonStyling?: ButtonStyling;
  active?: boolean;
};

export const Button: FC<Props> = ({
  click,
  buttonStyling = "default",
  label,
  active = true,
}) => {
  return (
    <div
      className={classNames("Button", `Button--${buttonStyling}`, {
        "Button--inactive": !active,
      })}
      onClick={() => active && click()}
    >
      <div className={` Button__label`}> {label} </div>
    </div>
  );
};
