import { FC, useMemo, useState } from "react";
import "./Overview.css";
import { Sync } from "../../Components/Sync/Sync";
import { OverviewCategories } from "../../Components/OverviewCategories/OverviewCategories";
import { OverviewFilters } from "../../Components/OverviewFilters/OverviewFilters";
import { OverviewItem } from "./OverviewItem";
import { SearchBar } from "../../Components/SearchBar/SearchBar";
import { Loading } from "../../Components/Loading/Loading";
import { ExportProductList } from "./ExportProductList";
import { useReactiveVar } from "@apollo/client";
import { searchVar, selectedCategoryVar, selectedFiltersVar } from "../../App";
import { Icon } from "../../Components/Icon/Icon";
import { filterGroupProducts } from "../../Utils/filterProducts";
import {
  GroupProductType,
  Metadata,
  useOverviewQuery,
  useUpdateGroupShowInShopMutation,
  useUpdateProductShowInShopMutation,
} from "../../Generated/graphql";
import { OverviewBtn } from "./OverviewBtn";
import { useHistory } from "react-router-dom";
import { QueryProduct } from "./QueryProduct.type";
import { isString } from "lodash";
import { useLightboxDispatch } from "../Main/LightboxContext";

const applyLimit =
  (limit: number) =>
  <T extends any>(list: T[]): { value: T[]; hasMore: boolean } => {
    if (list.length <= limit) {
      return {
        value: list,
        hasMore: false,
      };
    }

    return {
      value: list.slice(0, limit),
      hasMore: true,
    };
  };

const useOverviewProducts = () => {
  const selectedFilter = useReactiveVar(selectedFiltersVar);
  const selectedCategory = useReactiveVar(selectedCategoryVar);
  const search = useReactiveVar(searchVar);

  const { data, loading, error, refetch } = useOverviewQuery({
    fetchPolicy: "network-only",
  });

  const [updateProductShowInShop] = useUpdateProductShowInShopMutation();
  const [updateGroupShowInShop] = useUpdateGroupShowInShopMutation();

  const filteredProducts = useMemo(() => {
    if (!data?.groupProducts) {
      return [];
    }

    // Filter the products
    return filterGroupProducts(selectedFilter)({
      search,
      metadata: data.metadata,
      category: selectedCategory ?? undefined,
      products: data.groupProducts,
    });
  }, [data, search, selectedCategory, selectedFilter]);

  const handleChangeShowInShop = async (args: {
    type: GroupProductType;
    value: boolean;
    productId: string;
  }) => {
    switch (args.type) {
      // Handle Product update
      case GroupProductType.Single:
      case GroupProductType.Variant:
        await updateProductShowInShop({
          variables: {
            productId: args.productId,
            showInShop: args.value,
          },
        });
        break;
      // Handle Group update
      case GroupProductType.Group:
        await updateGroupShowInShop({
          variables: {
            groupId: args.productId,
            showInShop: args.value,
          },
        });
        break;
      default:
        return;
    }

    // Refetch the list to update the view
    await refetch();
  };

  return {
    error,
    loading,
    refetch,
    /** The product list after filtering */
    products: filteredProducts,
    /** The product list pre-filters */
    allProducts: data?.groupProducts ?? [],
    categories: data?.categories.filter((x) => isString(x)) ?? [],
    metadata: data?.metadata ?? [],
    handleChangeShowInShop,
  };
};

type OverviewListProps = {
  category?: string;
  metadata: Metadata[];
  limit?: number;
  products: QueryProduct[];
  onChangeShowInShop: (args: {
    type: GroupProductType;
    value: boolean;
    productId: string;
  }) => void;
};
const OverviewList: FC<OverviewListProps> = ({
  category,
  metadata,
  limit: limitConfig = 50,
  products,
  onChangeShowInShop,
}) => {
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [limit, setLimit] = useState(limitConfig);

  const lightboxDispatch = useLightboxDispatch();

  const limitedProducts = useMemo(() => {
    const { hasMore, value } = applyLimit(limit)(products);

    // Update the hasMoreProducts state, if it has changed
    if (hasMore !== hasMoreProducts) {
      setHasMoreProducts(hasMore);
    }

    return value;
  }, [products, hasMoreProducts, limit]);

  // Show more products in the category by simply increasing the limit
  const fetchMore = () => {
    setLimit((limit) => limit + 50);
  };

  const handleImageClick = (imageUrl: string) => {
    lightboxDispatch({ type: "OPEN_POPUP", imageUrl });
  };

  return (
    <>
      <div className="Overview__category">
        <div className="Overview__category-text">{category}</div>
      </div>
      {limitedProducts.map((product) => {
        return (
          <OverviewItem
            product={product}
            metadata={metadata}
            key={product.id}
            onChangeShowInShop={onChangeShowInShop}
            onImageClick={handleImageClick}
          />
        );
      })}
      {hasMoreProducts && (
        <div className="Overview__showMoreItem--button" onClick={fetchMore}>
          <div
            className="Overview__showMoreItem--text"
            style={{
              fontWeight: 600,
              marginRight: "5px",
            }}
          >
            Vis flere
          </div>
          <div className="Overview__showMoreItem--icon">
            <Icon name="arrowDown" width={10} />
          </div>
        </div>
      )}
    </>
  );
};

export const Overview = () => {
  const {
    products,
    allProducts,
    categories,
    metadata,
    error,
    loading,
    refetch,
    handleChangeShowInShop,
  } = useOverviewProducts();
  const search = useReactiveVar(searchVar);

  // use history
  const history = useHistory();

  const handleCreateVariantGroup = () => {
    history.push("/main/variant-group/new");
  };

  const productsByCategory = useMemo(() => {
    const mappedProducts = categories.reduce((map, category) => {
      // Honestly, I don't know why this is necessary, but it is
      if (category === null) {
        return map;
      }

      // Filter the products by category
      const filteredProducts = products.filter((product) => {
        return product.category === category;
      });

      // Add the filtered products to the map
      if (filteredProducts.length > 0) {
        map.set(category, filteredProducts);
      }

      return map;
    }, new Map<string, QueryProduct[]>());

    return Array.from(mappedProducts.entries()) as [string, QueryProduct[]][];
  }, [categories, products]);

  const retryQuery = () => {
    refetch();
  };

  return (
    <>
      <div className="Overview__search">
        {<ExportProductList products={products} />}
        <SearchBar value={search} changeValue={searchVar} />
      </div>
      <div className="Overview">
        <div className="Overview__menu">
          <div className="Overview__sync">
            <Sync />
          </div>
          <div className="Overview__clickables">
            <OverviewBtn
              title="Opret variantgruppe"
              icon={
                <Icon name="iconAdd" width={26} style={{ marginTop: "1px" }} />
              }
              onClick={handleCreateVariantGroup}
            />
            <div className="Overview__categories">
              <OverviewCategories />
            </div>
            <div className="Overview__status">
              <OverviewFilters allProducts={allProducts} />
            </div>
          </div>
        </div>

        <div className="Overview__content scrollbar__styled">
          {error && (
            <div onClick={retryQuery}>Der skete en fejl, prøv igen</div>
          )}
          {loading && <Loading />}
          {productsByCategory.map(([category, products]) => {
            if (!category) {
              return null;
            }
            return (
              <OverviewList
                limit={10}
                category={category}
                key={category}
                metadata={metadata}
                products={products}
                onChangeShowInShop={handleChangeShowInShop}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
