import "./WYSIWYG.css";

import { FC, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

type Props = {
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
};

export const WYSIWYG: FC<Props> = ({
  value: outsideValue,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState(outsideValue);

  const handleChange = (value: string) => {
    if (value === outsideValue) {
      // Don't publih non-changes
      return;
    }

    onChange(value);
  };

  // Update the state when the value from outside changes
  useEffect(() => {
    setValue(outsideValue);
  }, [outsideValue]);

  return (
    <div className="WYSIWYG">
      <CKEditor
        editor={ClassicEditor}
        disabled={disabled}
        config={{
          // plugins: [Essentials, Paragraph, Bold, Italic],
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "bold",
              //"link",
              "bulletedList",
              "numberedList",
            ],
          },
        }}
        data={value}
        onChange={(_e, editor) => {
          handleChange(editor.getData());
        }}
      />
    </div>
  );
};
