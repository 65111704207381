import React, { FC } from "react";

import "./EditImage.css";
import { noop } from "../../Utils/noop";

export type Image = {
  url: string;
  name: string;
  sorting: number;
};

type Props = {
  value: Image;
  isDragging: boolean;
  onChange: (newValue: Image) => void;
  onImageClick?: (imageUrl: string) => void;
};

export const EditImage: FC<Props> = ({
  value,
  isDragging,
  onChange,
  onImageClick = noop,
}) => {
  const openFile = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="EditImage" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="EditImage__Image">
        <div
          className="EditImage__ImagePreview u-pointer"
          onClick={() => onImageClick(value.url)}
        >
          <img src={value.url} alt="" />
        </div>
        <div className="EditImage__Border"></div>
        <div
          className="EditImage__FileName"
          onClick={() => openFile(value.url)}
        >
          {value.name}
        </div>
      </div>
    </div>
  );
};
