/* eslint-disable jsx-a11y/alt-text */
import React, { FC, Suspense, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./EditCategoryItems.css";
import { Icon } from "../../Components/Icon/Icon";
import {
  GroupProductType,
  useEditCategoryQuery,
} from "../../Generated/graphql";
import { formatProductDescription } from "../../Utils/formatProductDescription";
import { ProductStatus } from "../Overview/ProductStatus";

type Props = {
  category: string;
  activeItemId: string;
};

export const EditCategoryItems: FC<Props> = ({ category, activeItemId }) => {
  const { data } = useEditCategoryQuery({
    variables: { categories: [category] },
    fetchPolicy: "network-only",
  });

  return (
    <div className="EditCategoryItems">
      {data?.groupProducts.flatMap((item) => {
        // For legacy reasons, Products use productNumber as id, while Groups use id
        const id =
          item.type === GroupProductType.Group ? item.id : item.productNumber;

        // This is mostly type ceremony. For the correct product type, there will always be an id
        if (!id) return [];

        return (
          <Suspense fallback={<div>Henter...</div>} key={item.id}>
            <EditCategoryItem
              id={id}
              name={item.name}
              imageUrl={item.imageUrl ?? undefined}
              productNumber={item.productNumber ?? undefined}
              productData={item.productData ?? undefined}
              type={item.type}
              active={activeItemId === id}
            />
            {/* If the item is a group, we want to show its variants below it */}
            {item.variants?.map((variant) => (
              <EditCategoryItem
                key={variant.id}
                id={variant.productNumber}
                name={variant.name}
                imageUrl={variant.imageUrl ?? undefined}
                productNumber={variant.productNumber ?? undefined}
                productData={variant.productData ?? undefined}
                type={variant.type}
                active={activeItemId === variant.productNumber}
              />
            ))}
          </Suspense>
        );
      })}
      {/* {hasMore && (
        <div
          className="Overview__showMoreItems"
          onClick={() => {
            if (data?.products.length === 0) return;

            fetchMore({
              variables: {
                categories: [category],
                limit: 25,
                offset: data?.products.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (fetchMoreResult.products.length === 0) {
                  setHasMore(false);
                }

                return {
                  ...prev,
                  products: fetchMoreResult.products,
                };
              },
            });
          }}
        >
          <div className="Overview__showMoreItem--button">
            <div
              className="Overview__showMoreItem--text"
              style={{ fontWeight: 600, marginRight: "5px" }}
            >
              Vis flere{" "}
            </div>
            <div className="Overview__showMoreItem--icon">
              <Icon name="arrowDown" width={10} />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

type EditCategoryItemProps = {
  id: string;
  name: string;
  imageUrl?: string;
  productNumber?: string;
  productData: any | null;
  type: GroupProductType;
  active: boolean;
};

const EditCategoryItem: FC<EditCategoryItemProps> = ({
  id,
  name,
  imageUrl,
  productNumber,
  productData,
  type,
  active,
}) => {
  const history = useHistory();

  const handleClick = () => {
    switch (type) {
      case GroupProductType.Group:
        history.push(`../group/${id}`);
        break;
      default:
        history.push(`../product/${id}`);
        break;
    }
  };

  const productDescription = useMemo(() => {
    return formatProductDescription({
      prodNo: productNumber,
      productData,
    });
  }, [productData, productNumber]);

  return (
    <div
      className="EditCategoryItem"
      style={{
        backgroundColor: active ? "rgba(255, 255, 255, .5)" : "#E9E9E9",
      }}
      onClick={handleClick}
    >
      <div className="EditCategoryItem__indicator">
        <div
          className="EditCategoryItem__indicator-bar"
          style={{ opacity: active ? 1 : 0 }}
        />
      </div>
      <div className="EditCategoryItem__image">
        {imageUrl ? (
          <img className="EditCategoryItem__image-img" src={imageUrl} />
        ) : (
          <Icon name="noImageAvail" width={30} />
        )}
      </div>
      <div
        className="EditCategoryItem__info"
        style={{ color: active ? "#0575E6" : "#363636" }}
      >
        <div className="EditCategoryItem__info-name" title={name}>
          {name}
        </div>
        <div
          className="EditCategoryItem__info-description"
          title={productDescription}
        >
          {productDescription}
        </div>
      </div>
      {productData && (
        <div className="EditCategoryItem__status">
          <ProductStatus productData={productData} theme="circle" />
        </div>
      )}
    </div>
  );
};
