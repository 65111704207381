import { FC, useMemo, useState } from "react";
import { DragAndDropList } from "../../Components/DragAndDropList/DragAndDropList";
import { isString } from "lodash";

import "./EditVariants.css";
import classNames from "classnames";
import { Button } from "../../Components/Button/Button";
import { VariantSelector } from "../../Components/VariantSelector/VariantSelector";
import { VariantPropertiesSelector } from "../../Components/VariantPropertiesSelector/VariantPropertiesSelector";
import { Icon } from "../../Components/Icon/Icon";
import { AddVariantImage } from "./AddVariantImage";
import { Image } from "../../Components/EditImages/EditImage";
import { formatProductDescription } from "../../Utils/formatProductDescription";
import { ListItemNull } from "../../Components/ListItemNull/ListItemNull";
import { noop } from "../../Utils/noop";
import { useLightboxDispatch } from "../Main/LightboxContext";

type VariantProperty = { valueId: string; value: string; propertyId: string };

type Variant = {
  id: string;
  name: string;
  productNumber: string;
  imageUrl?: string | null;
  properties: VariantProperty[];
  productData: any;
};

type Property = {
  id: string;
  label: string;
  values: { id: string; value: string }[];
};

type ShowAddImageState =
  | { showPopup: false }
  | { showPopup: true; variantId: string };

type Props = {
  category?: string;
  variants: Variant[];
  properties: Property[];
  onAddVariants: (variantIds: string[]) => void;
  onRemoveVariant: (variantId: string) => void;
  onSortVariant: (variantId: string, sortOrder: number) => void;
  onUpdateProperties: (properties: string[]) => void;
  onChangePropertyValue: (
    variantId: string,
    propertyId: string,
    valueId: string
  ) => void;
  onAddNewPropertyValue: (propertyId: string, variantToUpdate: string) => void;
  onAddVariantImage: (variantId: string, image: Image) => void;
};

export const EditVariants: FC<Props> = ({
  category,
  variants,
  properties,
  onAddVariants,
  onRemoveVariant,
  onSortVariant,
  onUpdateProperties,
  onChangePropertyValue,
  onAddNewPropertyValue,
  onAddVariantImage,
}) => {
  const [showVariantSelector, setShowVariantSelector] = useState(false);
  const [showPropertiesSelector, setShowPropertiesSelector] = useState(false);
  const [showAddImage, setShowAddImage] = useState<ShowAddImageState>({
    showPopup: false,
  });

  const lightboxDispatch = useLightboxDispatch();

  const handleCancelSelectVariants = () => {
    setShowVariantSelector(false);
  };

  const handleSelectVariants = (variantIds: string[]) => {
    onAddVariants(variantIds);
    setShowVariantSelector(false);
  };

  const handleAddButtonclicked = () => {
    setShowVariantSelector(true);
  };

  const handleDeleteVariant = (args: { item: Variant }) => {
    onRemoveVariant(args.item.id);
  };

  const handleSort = (args: { curIndex: number; item: Variant }) => {
    onSortVariant(args.item.id, args.curIndex);
  };

  const handleConfigurePropertiesClick = () => {
    setShowPropertiesSelector(true);
  };

  const handleCancelConfigureProperties = () => {
    setShowPropertiesSelector(false);
  };

  const handleSelectProperties = (properties: string[]) => {
    onUpdateProperties(properties);
    setShowPropertiesSelector(false);
  };

  const handleVariantImageUpload = (variantId: string, image: Image) => {
    onAddVariantImage(variantId, image);
  };

  const handleAddNePropertyValue = (
    propertyId: string,
    variantToUpdate: string
  ) => {
    onAddNewPropertyValue(propertyId, variantToUpdate);
  };

  const handleChangePropertyValue = (
    variantId: string,
    propertyId: string,
    valueId: string
  ) => {
    onChangePropertyValue(variantId, propertyId, valueId);
  };

  const handleImageClick = (imageUrl: string) => {
    lightboxDispatch({ type: "OPEN_POPUP", imageUrl });
  };

  const selectedPropertiesIds = useMemo(() => {
    return properties.map((property) => property.id);
  }, [properties]);

  const hasVariants = useMemo(() => {
    return variants.length > 0;
  }, [variants]);

  return (
    <div className="EditVariants">
      <header className="EditVariants__Header">
        <h2 className="EditVariants__Headline">Varianter</h2>
        <div className="EditVariants__HeaderButtons">
          <Button
            label="Konfigurér"
            buttonStyling="configureProperties"
            click={handleConfigurePropertiesClick}
          />
        </div>
      </header>

      <div className="EditVariants__List">
        {hasVariants === true && (
          <div className="EditVariants__ListHeader">
            <div className="EditVariants__ListHeaderImage">Billede</div>
            <div className="EditVariants__ListHeaderProduct">Produkt</div>
            {properties.map(({ id, label }, i) => (
              <div
                key={id}
                className={classNames(
                  "EditVariants__ListHeaderProperty",
                  `EditVariants__ListHeaderProperty--index-${i}`
                )}
              >
                {label}
              </div>
            ))}
          </div>
        )}
        {hasVariants === true && (
          <DragAndDropList
            items={variants}
            onDelete={handleDeleteVariant}
            onSort={handleSort}
          >
            {({ item }) => (
              <EditVariant
                key={item.id}
                variant={item}
                properties={properties}
                onChangePropertyValue={(propertyId, valueId) =>
                  handleChangePropertyValue(item.id, propertyId, valueId)
                }
                onAddNewPropertyValue={handleAddNePropertyValue}
                onAddImageClick={() =>
                  setShowAddImage({ showPopup: true, variantId: item.id })
                }
                onImageClick={handleImageClick}
              />
            )}
          </DragAndDropList>
        )}
        {hasVariants === false && (
          <ListItemNull>Ingen valgte varianter</ListItemNull>
        )}
      </div>

      <div className="EditVariants__Add">
        <Button
          label="Tilføj variant"
          buttonStyling="editAdd"
          click={() => handleAddButtonclicked()}
        />
      </div>
      {showVariantSelector && (
        <VariantSelector
          category={category}
          onSelectVariants={handleSelectVariants}
          onCancel={handleCancelSelectVariants}
        />
      )}
      {showPropertiesSelector && (
        <VariantPropertiesSelector
          selectedProperties={selectedPropertiesIds}
          onCancel={handleCancelConfigureProperties}
          onSelectProperties={handleSelectProperties}
        />
      )}
      {showAddImage.showPopup === true && (
        <AddVariantImage
          onUpload={(file) => {
            handleVariantImageUpload(showAddImage.variantId, file);
          }}
          onCancel={() => setShowAddImage({ showPopup: false })}
        />
      )}
    </div>
  );
};

const EditVariant: FC<{
  variant: Variant;
  properties: Property[];
  onChangePropertyValue: (propertyId: string, valueId: string) => void;
  onAddNewPropertyValue: (propertyId: string, variantToUpdate: string) => void;
  onAddImageClick: () => void;
  onImageClick?: (imageUrl: string) => void;
}> = ({
  variant,
  properties,
  onChangePropertyValue,
  onAddNewPropertyValue,
  onAddImageClick,
  onImageClick,
}) => {
  const hasImage = isString(variant.imageUrl);

  const getPropertyValue = (
    propertyId: string
  ): VariantProperty | undefined => {
    const property = variant.properties.find(
      (property) => property.propertyId === propertyId
    );

    return property;
  };

  const handleChangeProperty = (
    propertyId: string,
    valueId: string,
    variantToUpdate: string
  ) => {
    // This indicates that we want to trigger the "Add new" flow for the given property
    if (valueId === "ADD_NEW") {
      onAddNewPropertyValue(propertyId, variantToUpdate);
      return;
    }

    // Do not accept the "Choose" value as a valid value
    if (valueId === "CHOOSE") {
      return;
    }

    onChangePropertyValue(propertyId, valueId);
  };

  const variantDescription = useMemo(() => {
    return formatProductDescription({
      prodNo: variant.productNumber,
      productData: variant.productData,
    });
  }, [variant]);

  return (
    <div className="EditVariant">
      <div className="EditVariant__Image">
        {hasImage && (
          <div
            className="EditVariant__ImagePreview u-pointer"
            onClick={() =>
              onImageClick ? onImageClick(variant.imageUrl ?? "") : noop
            }
          >
            <img src={variant?.imageUrl ?? ""} alt="" />
          </div>
        )}
        {!hasImage && (
          <div className="EditVariant__ImagePreview">
            <div className="EditVariant__AddImage" onClick={onAddImageClick}>
              <Icon name="iconAdd" width={26} />
            </div>
          </div>
        )}
      </div>
      <div className="EditVariant__Product">
        <div className="EditVariant__ProductName">{variant.name}</div>
        <div className="EditVariant__ProductDescription">
          {variantDescription}
        </div>
      </div>
      {properties.map((property, i) => {
        // Get the selected value for the property
        const propertyValue = getPropertyValue(property.id);

        // Sort the property values by the value property
        const propertyValues = property.values.sort((a, b) =>
          a.value.localeCompare(b.value)
        );

        return (
          <div
            key={property.id}
            className={classNames(
              "EditVariant__Property",
              `EditVariant__Property--index-${i}`
            )}
          >
            <select
              className="EditVariant__PropertySelector"
              value={propertyValue?.valueId ?? undefined}
              onChange={(e) =>
                handleChangeProperty(property.id, e.target.value, variant.id)
              }
            >
              <option value="CHOOSE">Vælg</option>
              <hr />
              {propertyValues.map(({ id, value }) => (
                <option value={id} key={id}>
                  {value}
                </option>
              ))}
              <hr />
              <option value="ADD_NEW">Tilføj ny</option>
            </select>

            <Icon
              name="arrowDown"
              width={11}
              className="EditVariant__PropertySelectorIcon"
            />
          </div>
        );
      })}
    </div>
  );
};
