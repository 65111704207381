import React, { FC, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import "./OverviewFilters.css";
import { QueryProduct } from "../../Containers/Overview/QueryProduct.type";
import { isNull, isUndefined } from "lodash";
import {
  OverviewFilterGroup,
  SelectedFilterWithLabel,
} from "./OverviewFilterGroup";
import { selectedFiltersVar } from "../../App";
import { Status, getProductStatus } from "../../Utils/getProductStatus";

type OverviewFilterProps = {
  /** We need the products to show the product status filter */
  allProducts: QueryProduct[];
};

export const OverviewFilters: FC<OverviewFilterProps> = ({ allProducts }) => {
  const selectedFilters = useReactiveVar(selectedFiltersVar);

  // Create data for the filter lists
  // Kept here if anyone should want the percentage filters back
  // const productStatusFilters: SelectedFilterWithLabel[] = [
  //   {
  //     selectedFilter: {
  //       type: "PERCENTAGE_COMPLETE",
  //       value: 25,
  //     },
  //     label: "0 - 25%",
  //   },
  //   {
  //     selectedFilter: {
  //       type: "PERCENTAGE_COMPLETE",
  //       value: 50,
  //     },

  //     label: "25% - 50%",
  //   },
  //   {
  //     selectedFilter: {
  //       type: "PERCENTAGE_COMPLETE",
  //       value: 75,
  //     },

  //     label: "50% - 75%",
  //   },
  //   {
  //     selectedFilter: {
  //       type: "PERCENTAGE_COMPLETE",
  //       value: 100,
  //     },

  //     label: "75% - 100%",
  //   },
  // ];

  // Create a list of unique product statuses by looping through all the products
  const productStatuses = useMemo(() => {
    // Create a Map of product statuses to remove duplicates
    const productStatusMap = allProducts.reduce((map, product) => {
      // Product groups do not have a status, only the variants.
      // Thus we can safely just check the status of the variants.
      // Otherwise we just check the status of the mother product
      const productsToCheck =
        product.__typename === "GroupProduct" ? [product] : product.variants;

      // If there are no products to check, we just return the map and continue
      if (isNull(productsToCheck) || isUndefined(productsToCheck)) {
        return map;
      }

      // Add each status to the map, keyed by the status code to ensure uniqueness
      productsToCheck.forEach((variant) => {
        const status = getProductStatus(variant);

        // Add to the set if not already there
        if (status !== null && !map.has(status.statusCode)) {
          map.set(status.statusCode, status);
        }
      });

      return map;
    }, new Map<string, Status>());

    // Convert to an array, ordered by the status name
    return Array.from(productStatusMap.values()).sort((a, b) =>
      a.statusText.localeCompare(b.statusText)
    );
  }, [allProducts]);

  // Create the product status filters from the unique statuses
  const productStatusFilters: SelectedFilterWithLabel[] = useMemo(() => {
    return productStatuses.map((status) => {
      return {
        selectedFilter: {
          type: "PRODUCT_STATUS",
          value: status.statusCode,
        },
        label: status.statusText,
      };
    });
  }, [productStatuses]);

  // Create the product type filter statically
  const productTypeFilters: SelectedFilterWithLabel[] = [
    {
      selectedFilter: {
        type: "PRODUCT_TYPE",
        value: "VariantGroup",
      },
      label: "Variantgruppe",
    },
  ];

  // Create the missing data filters statically
  const missingDataFilters: SelectedFilterWithLabel[] = [
    {
      selectedFilter: {
        type: "DATA_MISSING",
        value: "IMAGES",
      },
      label: "Billeder",
    },
    {
      selectedFilter: {
        type: "DATA_MISSING",
        value: "SHORT_DESCRIPTION",
      },
      label: "Kort beskrivelse",
    },
    {
      selectedFilter: {
        type: "DATA_MISSING",
        value: "LONG_DESCRIPTION",
      },
      label: "Lang beskrivelse",
    },
    {
      selectedFilter: {
        type: "DATA_MISSING",
        value: "META_DESCRIPTION",
      },
      label: "Meta beskrivelse",
    },
    {
      selectedFilter: {
        type: "DATA_MISSING",
        value: "SPECIFICATIONS",
      },
      label: "Specifikationer",
    },
  ];

  return (
    <>
      <OverviewFilterGroup
        label="Produktstatus"
        selectedFilters={selectedFilters}
        filters={productStatusFilters}
      />
      <OverviewFilterGroup
        label="Mangler data"
        selectedFilters={selectedFilters}
        filters={missingDataFilters}
      />
      <OverviewFilterGroup
        label="Type"
        selectedFilters={selectedFilters}
        filters={productTypeFilters}
      />
    </>
  );
};
