import { FC, Fragment } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { config } from "../../config";
import { Toolbar } from "../Layout/Toolbar/Toolbar";
import { Overview } from "../Overview/Overview";
import "./Main.css";
import { VariantGroupNew } from "../VariantGroupNew/VariantGroupNew";
import { LightboxProvider } from "./LightboxContext";
import { LightboxWithProvider } from "../../Components/Lightbox/Lightbox";
import { Edit } from "../Edit/Edit";

export const Main: FC<{}> = () => {
  const { url, path } = useRouteMatch();

  return (
    <LightboxProvider>
      <Toolbar logoName={config.logo.appLogoName}></Toolbar>
      <Route
        render={() => (
          <div className="Main">
            <Fragment>
              <Switch>
                <Route exact path={path}>
                  <Redirect to={`${url}/overview`} />
                </Route>
                <Route path="/test" render={() => <div>Test</div>} />
                <Route path={`${url}/overview`} render={() => <Overview />} />
                <Route path={`${url}/edit`} render={() => <Edit />} />
                <Route
                  path={`${url}/variant-group/new`}
                  render={() => <VariantGroupNew />}
                />
              </Switch>
            </Fragment>
          </div>
        )}
      />
      <LightboxWithProvider />
    </LightboxProvider>
  );
};
