import React, { FC, useState } from "react";

import "./Import.css";
import { ImportUploadDisplay } from "../../Components/ImportUploadDisplay/ImportUploadDisplay";
import { FailedUpload } from "../../Components/FailedUpload/FailedUpload";
import {
  useAddFileMutation,
  useGetPresignedUrlMutation,
} from "../../Generated/graphql";
import { config } from "../../config";
import { DropFiles } from "../../Components/FileUpload/DropFiles";
import { isNull, isUndefined } from "lodash";

type UploadFileSuccessResponse = {
  success: true;
  fileUrl: string;
};
type UploadFileFailResponse = { success: false };

type UploadFileResponse = UploadFileSuccessResponse | UploadFileFailResponse;

type InferProductDataFromName = (fileName: string) =>
  | {
      type: "success";
      productNumber: string;
      sortOrder: number;
    }
  | {
      type: "fail";
      reason: "invalidFileName";
    };

/**
 * Infer product data from the file name
 */
const inferProductDataFromName: InferProductDataFromName = (fileName) => {
  // Regex for splitting the file name
  // Note: This does not support UUIDs for product numbers, because of the dash
  // Were this to be supported, the format would need to be changed
  const regex = /(\d+)-(\d+)\.(\w+)/;

  // Match the regex against the file name
  const match = fileName.match(regex);

  if (!match) {
    return { type: "fail", reason: "invalidFileName" };
  }

  // Get the product number as string
  const productNumber = match[1];

  // Get the sort order as int
  const sortOrder = parseInt(match[2]);

  if (!productNumber || !Number.isFinite(sortOrder)) {
    return { type: "fail", reason: "invalidFileName" };
  }

  return {
    type: "success",
    productNumber,
    sortOrder,
  };
};

type Props = {
  keys: any;
};
export const Import: FC<Props> = ({ keys }) => {
  const [addFile] = useAddFileMutation();

  const [mutateGetPresignedUrl] = useGetPresignedUrlMutation();

  const [images, setImages] = useState<string[]>([]);
  const [failedUploads, setFailedUploads] = useState<string[]>([]);

  const uploadFile = async (
    file: File,
    bucketDir: string
  ): Promise<UploadFileResponse> => {
    const fileName = file.name;

    // Infer product data from the file name
    const productData = inferProductDataFromName(fileName);

    if (productData.type === "fail") {
      return { success: false } as UploadFileFailResponse;
    }

    // Get the upload path
    const presignedUrlRequest = await mutateGetPresignedUrl({
      variables: {
        bucketPath: bucketDir,
        fileName,
        fileType: file.type,
      },
    });

    if (
      isNull(presignedUrlRequest.data) ||
      isUndefined(presignedUrlRequest.data)
    ) {
      return { success: false } as UploadFileFailResponse;
    }

    const uploadRequest = await fetch(
      presignedUrlRequest.data.getPresignedUrl.presignedUrl,
      {
        body: file,
        method: "PUT",
      }
    );

    if (uploadRequest.status !== 200) {
      return { success: false } as UploadFileFailResponse;
    }

    try {
      await addFile({
        variables: {
          fileUrl: presignedUrlRequest.data.getPresignedUrl.finalUrl,
          prodNo: productData.productNumber,
          sorting: productData.sortOrder,
          productDataKey: config.fieldSettings.productImageField,
        },
      });

      return {
        success: true,
        fileUrl: presignedUrlRequest.data.getPresignedUrl.finalUrl,
      } satisfies UploadFileSuccessResponse;
    } catch (e) {
      console.log("Error adding file", e);

      return { success: false } satisfies UploadFileFailResponse;
    }
  };

  const handleDrop = async (files: File[]) => {
    for (const file of files) {
      const result = await uploadFile(file, "IMAGES").catch(
        () =>
          ({
            success: false,
          } as UploadFileFailResponse)
      );

      if (!result.success) {
        setFailedUploads((prev) => [...prev, file.name]);
      } else {
        setImages((prev) => [...prev, result.fileUrl]);
      }
    }
  };

  return (
    <div className="Import">
      <div className="Import__content">
        <div className="Import__title">Importer billeder</div>
        <div className="Import__imageDrop">
          <DropFiles theme="tall" onDrop={handleDrop} />
        </div>

        {failedUploads.length > 0 && (
          <div className="Import__failedUploads">
            <div className="Import__failedUploads-title">Fejl ved upload</div>
            {failedUploads.map((fail, i) => {
              return <FailedUpload name={fail} key={fail} index={i} />;
            })}
          </div>
        )}

        {images.length > 0 && <ImportUploadDisplay images={images} />}
      </div>
    </div>
  );
};
