import { FC, useCallback, useEffect, useState } from "react";
import { Loading } from "../../Components/Loading/Loading";
import {
  GroupProductType,
  useAddNewPropertyValueMutation,
  useAddVariantsToGroupMutation,
  useEditGroupQuery,
  useRemoveVariantsFromGroupMutation,
  useSortVariantInGroupMutation,
  useUpdateProductCustomDataMutation,
  useUpdateProductPropertyValueMutation,
  useUpdateVariantGroupMutation,
  useUpdateVariantGroupPropertiesMutation,
} from "../../Generated/graphql";
import "./Edit.css";
import { isNull, isUndefined } from "lodash";
import { ProductFields } from "./ProductFields";
import { EditActions } from "./EditActions";
import { StateButton } from "./StateButton";
import { useEditProductOrGroup } from "./useEditProductOrGroup";
import { EditVariants } from "./EditVariants";
import { ConfirmModal } from "../../Components/ConfirmModal/ConfirmModal";
import { AddNewPropertyValuePopup } from "./AddNewPropertyValuePopup";
import { Image } from "../../Components/EditImages/EditImage";
import { config } from "../../config";
import { editNavigationVar } from "../../App";

type RemoveVariantState =
  | {
      showPopup: true;
      variantToRemove: string;
    }
  | { showPopup: false };

type AddNewPropertyValueState =
  | {
      showPopup: true;
      propertyId: string;
      variantToUpdate: string;
    }
  | { showPopup: false };

type Props = {
  groupId: string;
};

/**
 * Screen to update a Variant Group
 */
export const EditGroup: FC<Props> = ({ groupId }) => {
  // State to control the confirm popup before removing a variant
  const [removeVariantState, setRemoveVariantState] =
    useState<RemoveVariantState>({ showPopup: false });

  // State to control the add new property value popup
  const [addNewPropertyValueState, setAddNewPropertyValueState] =
    useState<AddNewPropertyValueState>({ showPopup: false });

  // Fetch the current values
  const { loading, error, data, refetch } = useEditGroupQuery({
    variables: { id: groupId },
    fetchPolicy: "no-cache", // Avoid bug when navigating to new products
  });

  // Set up the mutation needed for saving data
  const [updateVariantGroup] = useUpdateVariantGroupMutation();

  // Set up the mutation needed for adding variants to a group
  const [addVariantsToGroup] = useAddVariantsToGroupMutation();

  // Set up the mutation needed for removing variants from a group
  const [removeVariantsFromGroup] = useRemoveVariantsFromGroupMutation();

  // Set up mutation needed for sorting a variant
  const [sortVariantInGroup] = useSortVariantInGroupMutation();

  // Set up mutation needed for updating group properties
  const [updateVariantGroupProperties] =
    useUpdateVariantGroupPropertiesMutation();

  // Set up mutation needed for updating product property values
  const [updateProductPropertyValue] = useUpdateProductPropertyValueMutation();

  // Set up mutation needed for adding a new property value
  const [addNewProductPropertyValue] = useAddNewPropertyValueMutation();

  const [updateProductCustomData] = useUpdateProductCustomDataMutation();

  const {
    customData,
    setCustomData,
    showInShop,
    handleChangeShowInShop,
    category,
    hasCustomDataChanged,
    hasShowInShopChanged,
    isDirty,
  } = useEditProductOrGroup({
    initialCustomData: data?.groupProduct?.customData ?? null,
    initialProductData: {}, // Groups have no product data, so we just pass an empty object
    initialShowInShop: data?.groupProduct?.showInShop ?? null,
  });

  /**
   * Add variants to the group
   */
  const handleAddVariants = async (variantIds: string[]) => {
    await addVariantsToGroup({
      variables: { variantGroupId: groupId, variantIds: variantIds },
    });

    // Refetch the product
    refetch();
  };

  /**
   * Show the confirm popup before removing a variant
   */
  const handleRemoveVariant = async (variantId: string) => {
    setRemoveVariantState({ showPopup: true, variantToRemove: variantId });
  };

  /**
   * Remove a variant from the group
   */
  const handleRemoveVariantClicked = async (doIt: boolean) => {
    // Do nothing if the user clicked cancel
    if (doIt === false) {
      // Hide the popup
      setRemoveVariantState({ showPopup: false });

      return;
    }

    // Ensure we are in the right state before continuing
    if (removeVariantState.showPopup === false) {
      return;
    }

    // Attempt to remove the variant
    try {
      await removeVariantsFromGroup({
        variables: {
          variantGroupId: groupId,
          variantIds: [removeVariantState.variantToRemove],
        },
      });
    } catch (e) {
      alert("Kunne ikke fjerne varianten. Prøv igen senere.");
      return;
    }

    // Refetch the product
    refetch();

    // Hide the popup
    setRemoveVariantState({ showPopup: false });
  };

  const handleSave = useCallback(async () => {
    // Just a sanity check to avoid saving null data
    if (isNull(customData)) {
      alert("Kunne ikke gemme produktet. Prøv igen senere.");
      return;
    }

    // Only update the fields that have changed
    const dataToUpdate = {
      ...{ customData: hasCustomDataChanged ? customData : undefined },
      ...{ showInShop: hasShowInShopChanged ? showInShop : undefined },
    };

    // Update the group
    await updateVariantGroup({
      variables: { id: groupId, ...dataToUpdate },
    });

    // Refetch the product
    await refetch();
  }, [
    customData,
    groupId,
    hasCustomDataChanged,
    hasShowInShopChanged,
    refetch,
    showInShop,
    updateVariantGroup,
  ]);

  const handleSortVariant = async (variantId: string, sortOrder: number) => {
    await sortVariantInGroup({
      variables: { variantGroupId: groupId, variantId, sortOrder },
    });

    // Refetch the product
    await refetch();
  };

  const handleUpdateProperties = async (properties: string[]) => {
    await updateVariantGroupProperties({
      variables: {
        variantGroupId: groupId,
        propertyIds: properties.length > 0 ? properties : null,
      },
    });

    // Refetch the product
    await refetch();
  };

  const handleChangePropertyValue = async (
    productId: string,
    propertyId: string,
    valueId: string
  ) => {
    await updateProductPropertyValue({
      variables: { productId, propertyId, valueId },
    });

    // Refetch the product
    await refetch();
  };

  // Trigger the add new property value flow
  const handleAddNewPropertyValueClicked = (
    propertyId: string,
    variantToUpdate: string
  ) => {
    setAddNewPropertyValueState({
      showPopup: true,
      propertyId,
      variantToUpdate,
    });
  };

  const handleCancelAddNewPropertyValue = () => {
    setAddNewPropertyValueState({ showPopup: false });
  };

  const handleAddNewPropertyValue = async (
    propertyId: string,
    value: string
  ) => {
    // This is mostly a guard for the type system
    if (addNewPropertyValueState.showPopup === false) {
      return;
    }

    // Add the new value
    const { data } = await addNewProductPropertyValue({
      variables: {
        propertyId,
        value,
      },
    });

    if (data === null || data === undefined) {
      alert("Kunne ikke tilføje den nye værdi. Prøv igen senere.");

      return;
    }

    // Select the value for the variant
    await updateProductPropertyValue({
      variables: {
        productId: addNewPropertyValueState.variantToUpdate,
        propertyId,
        valueId: data.createPropertyValue.id,
      },
    });

    // Refetch the product
    await refetch();

    // Hide the popup
    setAddNewPropertyValueState({ showPopup: false });
  };

  const handleAddVariantImage = async (variantId: string, image: Image) => {
    // Get the main image key
    const imageMetaKey = config.fieldSettings.productImageField;

    // Update the variant
    await updateProductCustomData({
      variables: {
        productId: variantId,
        metadataKey: imageMetaKey,
        value: [image],
      },
    });

    // Refetch the product
    await refetch();
  };

  // We need to pass an empty object to the product fields component
  const productData = {};

  // This edit screen is only for groups, so we can set the type to group
  const groupProductType = GroupProductType.Group;

  // Set the navigation var to properly highlight the selected product
  useEffect(() => {
    // We need both the category and the product number to set the navigation var
    if (category === null || groupId === null) {
      return;
    }

    // Set the navigation var
    editNavigationVar({
      selectedCategory: category,
      selectedProductNumber: groupId,
    });
  }, [category, groupId]);

  if (
    loading ||
    error ||
    isUndefined(data) ||
    isUndefined(data.groupProduct) ||
    isNull(data.groupProduct)
  ) {
    return <Loading />;
  }

  return (
    <>
      <div className="Edit__content-wrapper">
        <div className="Edit__content">
          <div className="Edit__content-inner">
            <ProductFields
              customData={customData ?? {}}
              customDataChanged={(customData) => setCustomData(customData)}
              metadata={data.metadata}
              productData={productData}
              groupProductType={groupProductType}
            />
          </div>
          <div className="Edit__hr"></div>
          <div className="Edit__variants">
            {data.groupProduct.variants && (
              <EditVariants
                category={category ?? undefined}
                variants={data.groupProduct.variants}
                properties={data.propertiesForGroup ?? []}
                onAddVariants={handleAddVariants}
                onRemoveVariant={handleRemoveVariant}
                onSortVariant={handleSortVariant}
                onUpdateProperties={handleUpdateProperties}
                onChangePropertyValue={handleChangePropertyValue}
                onAddNewPropertyValue={handleAddNewPropertyValueClicked}
                onAddVariantImage={handleAddVariantImage}
              />
            )}
          </div>
        </div>
        <div className="Edit__actions">
          <EditActions
            onSave={() => handleSave()}
            saveState={isDirty ? "dirty" : "clean"}
            lastUpdateUsername={data.groupProduct.updatedBy ?? undefined}
            lastUpdatedAt={data.groupProduct.updatedAt ?? undefined}
            otherActions={
              <>
                {showInShop !== null && (
                  <StateButton
                    click={handleChangeShowInShop}
                    state={showInShop}
                  />
                )}
              </>
            }
          />
        </div>
      </div>
      {removeVariantState.showPopup && (
        <ConfirmModal
          primaryText="Fjern variant"
          secondaryText="Fortryd"
          promptText="Er du sikker på at du vil fjerne varianten fra gruppen?"
          respond={handleRemoveVariantClicked}
        />
      )}
      {addNewPropertyValueState.showPopup && (
        <AddNewPropertyValuePopup
          propertyId={addNewPropertyValueState.propertyId}
          onAdd={handleAddNewPropertyValue}
          onCancel={handleCancelAddNewPropertyValue}
        />
      )}
    </>
  );
};
