import { useHistory, useLocation } from "react-router-dom";

import "./SettingsMenu.css";

export const SettingsMenu = () => {
  const location = useLocation();
  const history = useHistory();

  const activeLinkStyles = {
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#0575E6",
  };

  const settings = [
    {
      title: "Brugere",
      path: "/settings/users",
      selected: location.pathname.match(/settings\/users($|\/)/),
    },
    {
      title: "Synkronisering",
      path: "/settings/sync",
      selected: location.pathname.match(/settings\/sync($|\/)/),
    },
    {
      title: "Import af billeder",
      path: "/settings/import",
      selected: location.pathname.match(/settings\/import($|\/)/),
    },
    {
      title: "Indlæs fra Excel",
      path: "/settings/importprod",
      selected: location.pathname.match(/settings\/importprod($|\/)/),
    },
    {
      title: "Produktegenskaber",
      path: "/settings/properties",
      selected: location.pathname.match(/settings\/properties($|\/)/),
    },
  ];

  const selectedIndex = settings.findIndex((s) => s.selected);
  const activeBlotLocation = 100 + 62 * selectedIndex;

  return (
    <div className="SettingsMenu">
      <div className="SettingsMenu__title">
        <div className="SettingsMenu__title-text">Indstillinger</div>
      </div>

      <div
        className="SettingsMenu__activeblob"
        style={{ top: activeBlotLocation }}
      />

      {settings.map((setting) => (
        <div
          key={setting.title}
          className="SettingsMenu__link"
          style={setting.selected ? activeLinkStyles : undefined}
          onClick={() => history.push(setting.path)}
        >
          {setting.title}
        </div>
      ))}
    </div>
  );
};
