import { DragAndDropList } from "../DragAndDropList/DragAndDropList";
import { Button } from "../Button/Button";

import { FC, useMemo, useState } from "react";
import { EditImage, Image } from "./EditImage";
import { FileUpload, FileUploadFile } from "../FileUpload/FileUpload";
import { ListItemNull } from "../ListItemNull/ListItemNull";
import { noop } from "../../Utils/noop";

type Props = {
  value: Image[];
  onChange: (newValue: Image[]) => void;
  /**
   * Provide an async function eg. to show a confirmation modal
   * Reject the promise to cancel the delete
   * */
  onBeforeDelete?: (index: number) => Promise<void>;
  onImageClick?: (imageUrl: string) => void;
};

export const EditImages: FC<Props> = ({
  value: items,
  onChange,
  onBeforeDelete = () => Promise.resolve(),
  onImageClick = noop,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);

  const handleUpdate = (newValue: Image, index: number) => {
    const newItems = [...items];
    newItems[index] = newValue;
    onChange(newItems);
  };

  const toggleUploader = () => {
    setShowFileUpload((value) => !value);
  };

  const handleSort = ({
    prevIndex,
    curIndex,
  }: {
    curIndex: number;
    prevIndex: number;
  }) => {
    const newItems = [...items];
    const [removed] = newItems.splice(prevIndex, 1);
    newItems.splice(curIndex, 0, removed);
    onChange(newItems);
  };

  const handleDelete = async (args: { index: number }) => {
    try {
      await onBeforeDelete(args.index);
    } catch (e) {
      // User cancelled delete
      return;
    }

    const newItems = [...items];
    newItems.splice(args.index, 1);
    onChange(newItems);
  };

  const handleUpload = (files: FileUploadFile[]) => {
    const newItems = [...items];
    const newFiles = files.map<Image>((file, i) => ({
      name: file.fileName,
      url: file.url,
      sorting: items.length + i,
    }));
    onChange([...newItems, ...newFiles]);
    setShowFileUpload(false);
  };

  const showList = useMemo(() => items.length > 0, [items.length]);

  const showNullScreen = useMemo(
    () => items.length === 0 && !showFileUpload,
    [items.length, showFileUpload]
  );

  return (
    <div className="EditImages">
      {showList && (
        <DragAndDropList
          items={items}
          onSort={handleSort}
          onDelete={handleDelete}
        >
          {({ item, index }, { isDragging }) => (
            <EditImage
              key={index}
              isDragging={isDragging}
              value={item}
              onChange={(newItem) => handleUpdate(newItem, index)}
              onImageClick={onImageClick}
            />
          )}
        </DragAndDropList>
      )}
      {showNullScreen && <ListItemNull>Ingen billeder</ListItemNull>}
      {showFileUpload && (
        <div className="EditImages__FileUpload">
          <FileUpload bucketDir="IMAGES" onUpload={handleUpload} />
        </div>
      )}
      <div className="EditImages__Add">
        <Button
          label={showFileUpload ? "Annuller" : "Tilføj"}
          buttonStyling="editAdd"
          click={() => toggleUploader()}
        />
      </div>
    </div>
  );
};
