import {
  useDeletePropertyMutation,
  usePropertiesQuery,
} from "../../Generated/graphql";
import { useHistory } from "react-router-dom";
import { SettingsListItem } from "../../Components/SettingsListItem/SettingsListItem";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";

export const Properties = () => {
  const history = useHistory();
  const { data: propertiesData, refetch } = usePropertiesQuery({
    fetchPolicy: "network-only",
  });
  const [deleteProperty] = useDeletePropertyMutation();

  const handleDeleteProperty = async (id: string) => {
    await deleteProperty({
      variables: {
        id,
      },
    });

    refetch();
  };

  const handleOnSave = () => {
    history.push("properties/new");
  };

  const hasProperties = propertiesData && propertiesData.properties.length > 0;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
    },
  ];

  return (
    <SettingsForm
      breadcrumbs={breadcrumbs}
      onSave={handleOnSave}
      buttonLabel="Opret ny"
      theme="slim"
    >
      <div>
        {hasProperties === false && (
          <SettingsListItem name="Ingen egenskaber" />
        )}

        {propertiesData?.properties.map((property) => (
          <SettingsListItem
            name={property.label}
            onClick={() =>
              history.push(`/settings/properties/${property.id}/values`)
            }
            deleteClick={() => handleDeleteProperty(property.id)}
            iconClick={() =>
              history.push(`/settings/properties/${property.id}`)
            }
            key={property.id}
          />
        ))}
      </div>
    </SettingsForm>
  );
};
