import { FC } from "react";

import { Select, SelectOption } from "../../Components/Select/Select";
import { useDropdownMetaFieldQuery } from "../../Generated/graphql";

type Props = {
  metadataId: string;
  value: string;
  disabled: boolean;
  onChange: (newValue: string) => void;
};

export const DropdownMetaField: FC<Props> = ({
  metadataId,
  value,
  disabled,
  onChange,
}) => {
  const { loading, data } = useDropdownMetaFieldQuery({
    variables: {
      metadataId,
    },
  });

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && data && (
        <Select
          change={onChange}
          value={value ?? ""}
          type="default"
          disabled={disabled}
          showFilter={true}
        >
          {data.metadataValues.map((value) => (
            <SelectOption key={value} value={value}>
              {value}
            </SelectOption>
          ))}
        </Select>
      )}
    </>
  );
};
