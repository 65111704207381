import { FC } from "react";
import { DropFiles } from "./DropFiles";
import { useGetPresignedUrlMutation } from "../../Generated/graphql";
import { isNull, isUndefined } from "lodash";
import { noop } from "../../Utils/noop";

type Props = {
  bucketDir: string;
  theme?: "medium" | "tall" | "variant-upload";
  onUpload: (files: FileUploadFile[]) => void;
  onLoading?: () => void;
};

async function runAsyncSerial<T>(
  promiseFunctions: ((...args: any[]) => Promise<T>)[]
): Promise<T[]> {
  const results: T[] = [];
  for (const promiseFunction of promiseFunctions) {
    const result = await promiseFunction();
    results.push(result);
  }
  return results;
}

export type FileUploadFile = {
  url: string;
  fileName: string;
};

type UploadFileSuccessResponse = {
  success: true;
  file: FileUploadFile;
};
type UploadFileFailResponse = { success: false };

type UploadFileResponse = UploadFileSuccessResponse | UploadFileFailResponse;

export const FileUpload: FC<Props> = ({
  bucketDir,
  theme,
  onUpload,
  onLoading = noop,
}) => {
  const [getPresignedUrl] = useGetPresignedUrlMutation();

  const uploadFile = async (file: File): Promise<UploadFileResponse> => {
    const fileName = file.name;

    // Get the S3 upload url
    const presignedUrlRequest = await getPresignedUrl({
      variables: {
        bucketPath: bucketDir,
        fileName,
        fileType: file.type,
      },
    });

    if (
      isNull(presignedUrlRequest.data) ||
      isUndefined(presignedUrlRequest.data)
    ) {
      return { success: false } as UploadFileFailResponse;
    }

    const uploadRequest = await fetch(
      presignedUrlRequest.data.getPresignedUrl.presignedUrl,
      {
        body: file,
        method: "PUT",
      }
    );

    if (uploadRequest.status !== 200) {
      return { success: false } as UploadFileFailResponse;
    }

    return {
      success: true,
      file: {
        fileName: fileName,
        url: presignedUrlRequest.data.getPresignedUrl.finalUrl,
      },
    } as UploadFileSuccessResponse;
  };

  const handleDrop = async (files: File[]) => {
    onLoading();

    const uploadedFiles = await runAsyncSerial<UploadFileResponse>(
      files.map((x) => () => uploadFile(x))
    );

    const successFiles = uploadedFiles
      // Typeguard to filter out failed uploads
      .filter((x): x is UploadFileSuccessResponse => x.success)
      .map((file: UploadFileSuccessResponse) => file.file);

    onUpload(successFiles);
  };

  return (
    <>
      <DropFiles onDrop={handleDrop} theme={theme} />
    </>
  );
};
