import { DragAndDropList } from "../DragAndDropList/DragAndDropList";
import { Button } from "../Button/Button";

import { FC, useMemo, useState } from "react";
import { EditFile, File } from "./EditFile";
import { FileUpload, FileUploadFile } from "../FileUpload/FileUpload";
import { ListItemNull } from "../ListItemNull/ListItemNull";

type Props = {
  value: File[];
  onChange: (newValue: File[]) => void;
  /**
   * Provide an async function eg. to show a confirmation modal
   * Reject the promise to cancel the delete
   * */
  onBeforeDelete?: (index: number) => Promise<void>;
};

export const EditFiles: FC<Props> = ({
  value: items,
  onChange,
  onBeforeDelete = () => Promise.resolve(),
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);

  const handleUpdate = (newValue: File, index: number) => {
    const newItems = [...items];
    newItems[index] = newValue;
    onChange(newItems);
  };

  const toggleUploader = () => {
    setShowFileUpload((value) => !value);
  };

  const handleSort = ({
    prevIndex,
    curIndex,
  }: {
    prevIndex: number;
    curIndex: number;
  }) => {
    const newItems = [...items];
    const [removed] = newItems.splice(prevIndex, 1);
    newItems.splice(curIndex, 0, removed);
    onChange(newItems);
  };

  const handleDelete = async (args: { index: number }) => {
    try {
      await onBeforeDelete(args.index);
    } catch (e) {
      // User cancelled delete
      return;
    }

    const newItems = [...items];
    newItems.splice(args.index, 1);
    onChange(newItems);
  };

  const handleUpload = (files: FileUploadFile[]) => {
    const newItems = [...items];
    const newFiles = files.map<File>((file, i) => ({
      description: "",
      name: file.fileName,
      url: file.url,
      sorting: items.length + i,
    }));
    onChange([...newItems, ...newFiles]);
    setShowFileUpload(false);
  };

  const showList = useMemo(() => items.length > 0, [items.length]);

  const showNullScreen = useMemo(
    () => items.length === 0 && !showFileUpload,
    [items.length, showFileUpload]
  );

  return (
    <div className="EditFiles">
      {showList && (
        <DragAndDropList
          items={items}
          onSort={handleSort}
          onDelete={handleDelete}
        >
          {({ item, index }, { isDragging }) => (
            <EditFile
              key={index}
              isDragging={isDragging}
              value={item}
              onChange={(newItem) => handleUpdate(newItem, index)}
            />
          )}
        </DragAndDropList>
      )}
      {showNullScreen && <ListItemNull>Ingen filer</ListItemNull>}
      {showFileUpload && (
        <div className="EditFiles__FileUpload">
          <FileUpload bucketDir="FILES" onUpload={handleUpload} />
        </div>
      )}
      <div className="EditFiles__Add">
        <Button
          label={showFileUpload ? "Annuller" : "Tilføj"}
          buttonStyling="editAdd"
          click={() => toggleUploader()}
        />
      </div>
    </div>
  );
};
