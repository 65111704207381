import React, { FC } from "react";

import "./ConfirmModal.css";
import { Modal } from "../Modal/Modal";

type Props = {
  primaryText?: string;
  secondaryText?: string;
  promptText?: string;
  respond: (res: boolean) => void;
};

export const ConfirmModal: FC<Props> = ({
  primaryText = "Slet",
  secondaryText = "Fortryd",
  promptText = "Er du sikker på at du vil slette?",
  respond,
}) => {
  const primaryButton = {
    text: primaryText,
    onClick: () => respond(true),
  };

  const secondaryButton = {
    text: secondaryText,
    onClick: () => respond(false),
  };

  return (
    <Modal
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      className="ConfirmModal"
    >
      <div className="ConfirmModal__text">{promptText}</div>
    </Modal>
  );
};
