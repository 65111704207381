import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { animated, useSpring } from "react-spring";

import "./Select.css";

import { noop } from "../../Utils/noop";
import { Icon } from "../Icon/Icon";
import { useBoolean, useOnClickOutside } from "usehooks-ts";
import classNames from "classnames";
import { SelectOption } from "./SelectOption/SelectOption";

export * from "./SelectOption/SelectOption";

type Props = {
  type?: "default" | "sync";
  value: string;
  defaultText?: string;
  disabled?: boolean;
  showFilter?: boolean;
  change?: (value: string) => void;
};

export const Select: FC<PropsWithChildren<Props>> = ({
  type = "default",
  value,
  defaultText = "Vælg...",
  disabled = false,
  showFilter = false,
  children,
  change = noop,
}) => {
  const {
    value: isOpen,
    toggle: toggleOpen,
    setValue: setOpen,
  } = useBoolean(false);

  const [filterText, setFilterText] = useState("");

  const outerRef = useRef(null);
  const optionValuesRef = useRef<HTMLDivElement | null>(null);
  const filterInputRef = useRef<HTMLInputElement | null>(null);

  const { height } = useSpring({
    from: { height: 0 },
    to: { height: isOpen ? "auto" : 0 },
  });

  useOnClickOutside(outerRef, () => setOpen(false));

  const select = (value: string) => {
    change(value);
    close();
  };

  const getLabel = (value: string) => {
    const predicate = (child: ReactElement<{ value: string }>) =>
      child?.props?.value === value;

    const childrenArray = React.Children.toArray(children) as ReactElement[];

    const selectedChild = childrenArray.find(predicate);

    return selectedChild ? (
      selectedChild
    ) : (
      <SelectOption value="">{defaultText}</SelectOption>
    );
  };

  const childrenWithSelectedPoperty = React.Children.map(
    children as ReactElement[],
    (child: ReactElement) => {
      return React.cloneElement(child, {
        click: select,
        isSelected: child.props.value === value,
      });
    }
  );

  // Filter children by the filterText value
  const filteredChildren = useMemo(() => {
    if (!showFilter) {
      return childrenWithSelectedPoperty;
    }

    return childrenWithSelectedPoperty.filter((child) =>
      child.props?.value
        ?.toLowerCase()
        ?.includes(filterText.toLocaleLowerCase())
    );
  }, [childrenWithSelectedPoperty, filterText, showFilter]);

  // Focus the filter input when the select opens
  useEffect(() => {
    // Only run on when opening
    if (!isOpen) {
      return;
    }

    // Can't run if we can't find the filter input
    if (!filterInputRef.current) {
      return;
    }

    // Focus the filter input
    filterInputRef.current.focus();
  }, [isOpen]);

  // Reset filter text when the select closes
  useEffect(() => {
    if (!isOpen) {
      setFilterText("");
    }
  }, [isOpen]);

  // Scroll to selected option
  useEffect(() => {
    // Only run when the select opens
    if (!isOpen) {
      return;
    }

    // Can't run if we can't find the option container
    if (!optionValuesRef.current) {
      return;
    }

    // Get the selected element
    const selectedNode = optionValuesRef.current.getElementsByClassName(
      "is-selected"
    )?.[0] as HTMLElement;

    // Can't run if we can't find the selected option
    if (!selectedNode) {
      return;
    }

    // Scroll to the selected option
    optionValuesRef.current.scrollTo(0, selectedNode.offsetTop);
  }, [isOpen]);

  const close = () => {
    setOpen(false);
  };

  return (
    <div
      className={classNames(["Select", `Select--${type}`], {
        "is-open": isOpen,
        "is-disabled": disabled,
      })}
      ref={outerRef}
    >
      <div className="Select__element" onClick={disabled ? noop : toggleOpen}>
        <div className="Select__value u-no-text-select">{getLabel(value)}</div>
        <Icon name="arrowDown" width={11} className="Select__icon" />
      </div>

      <animated.div style={{ height }} className="Select__options">
        {showFilter && (
          <div className="Select__filter">
            <input
              type="text"
              className="Select__filter-input"
              placeholder="Søg..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              ref={filterInputRef}
            />
          </div>
        )}
        <div className="Select__optionValues" ref={optionValuesRef}>
          {filteredChildren}
        </div>
      </animated.div>
    </div>
  );
};
