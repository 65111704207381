import { FC, KeyboardEvent, useState } from "react";

import "./LoginModal.css";
import { Icon } from "../Icon/Icon";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { ValidIcons } from "../Icon/Icon-list";

type Props = {
  company: string;
  logo: ValidIcons;
  logoWidth: number;
  logIn: (username: string, password: string) => void;
};

export const LoginModal: FC<Props> = ({ company, logo, logoWidth, logIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      logIn(username, password);
    }
  };

  return (
    <div className="LoginModal">
      <div className="LoginModal__header">
        <div className="LoginModal__logo">
          {" "}
          <Icon name={logo} width={logoWidth} />{" "}
        </div>
        <div className="LoginModal__title"> Log ind </div>
      </div>
      <div className="LoginModal__sub-header">
        <div className="LoginModal__pim"> Product Information Management </div>
        <div className="LoginModal__company"> {company} </div>
      </div>
      <div className="LoginModal__login-form">
        <div className="LoginModal__username">
          <TextInput
            placeholder="Brugernavn"
            change={(e) => setUsername(e.target.value)}
            value={username}
            styling="loginForm"
            keyPressed={handleKeyPress}
          />
        </div>
        <div className="LoginModal__pwd">
          <TextInput
            placeholder="Adgangskode"
            type="password"
            change={(e) => setPassword(e.target.value)}
            value={password}
            styling="loginForm"
            keyPressed={handleKeyPress}
          />
        </div>
        <div className="LoginModal__login-button">
          <Button
            label="Log ind"
            click={() => logIn(username, password)}
            buttonStyling="login"
          />
        </div>
      </div>
    </div>
  );
};
