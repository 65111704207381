import { FC, useState } from "react";
import {
  FileUpload,
  FileUploadFile,
} from "../../Components/FileUpload/FileUpload";
import { Modal } from "../../Components/Modal/Modal";

import "./AddVariantImage.css";
import { Image } from "../../Components/EditImages/EditImage";
import { Loading } from "../../Components/Loading/Loading";
import { Icon } from "../../Components/Icon/Icon";

type State = "initial" | "loading" | "uploaded";

type Props = {
  onUpload: (file: Image) => void;
  onCancel: () => void;
};

export const AddVariantImage: FC<Props> = ({ onUpload, onCancel }) => {
  const [state, setState] = useState<State>("initial");

  const handleUpload = (files: FileUploadFile[]) => {
    // We only accept 1 file in this component
    const firstUpload = files[0];

    onUpload(fileUploadFileToImage(firstUpload));

    setState("uploaded");
  };

  const handleOnLoading = () => {
    setState("loading");
  };

  const primaryButton = {
    text: "Ok",
    onClick: onCancel,
  };

  return (
    <Modal primaryButton={primaryButton} className="AddVariantImage">
      {state === "loading" && (
        <div className="AddVariantImage__Loader">
          <Loading />
        </div>
      )}
      {state === "uploaded" && (
        <div className="AddVariantImage__Success">
          <Icon name="syncSucces" width={68} height={68} />
        </div>
      )}
      <div
        className="AddVariantImage__DropZone"
        style={{ visibility: state === "initial" ? "visible" : "hidden" }}
      >
        <FileUpload
          bucketDir="IMAGES"
          onUpload={handleUpload}
          theme="variant-upload"
          onLoading={handleOnLoading}
        />
      </div>
    </Modal>
  );
};

/** Converts a FileUploadFile to an Image */
const fileUploadFileToImage = (file: FileUploadFile, sorting = 0): Image => ({
  name: file.fileName,
  url: file.url,
  sorting,
});
