import React, { useEffect, useState } from "react";

import "./SyncPlan.css";
import { Button } from "../Button/Button";
import { SyncPlanItem } from "./SyncPlanItem/SyncPlanItem";
import updateSchedule from "../../GraphQL/Mutations/updateSchedule";
import createSchedule from "../../GraphQL/Mutations/createSchedule";
import { useMutation } from "@apollo/client";

export const SyncPlan = ({ plan, deleteTask }) => {
  const [planCopy, setPlanCopy] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [mutateUpdate] = useMutation(updateSchedule);
  const [mutateCreate] = useMutation(createSchedule);

  useEffect(() => {
    return async () => {
      await mutateUpdate({
        variables: {
          schedule: planCopy,
        },
      });
      await mutateCreate({
        variables: {
          schedule: newItems,
        },
      });
    };
    // We need this to only run on un-mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dayChangeHandler = (input, index) => {
    const timePlanCopy = [...plan];

    timePlanCopy[index].day = input;

    // this.setState({ plan: timePlanCopy });
  };

  const timeChangeHandler = (input, index) => {
    const timePlanCopy = [...plan];

    timePlanCopy[index].time = input;

    setPlanCopy(timePlanCopy);

    // this.setState({ plan: timePlanCopy });
  };

  const newDayChangeHandler = (input, index) => {
    const timePlanCopy = [...newItems];

    timePlanCopy[index].day = input;

    setNewItems(timePlanCopy);

    // this.setState({ newItems: timePlanCopy });
  };

  const newTimeChangeHandler = (input, index) => {
    const timePlanCopy = [...newItems];

    timePlanCopy[index].time = input;
    setNewItems(timePlanCopy);

    // this.setState({ newItems: timePlanCopy });
  };

  const addNewSlot = () => {
    const newItem = { day: 1, time: 1 };

    setNewItems((previous) => [...previous, newItem]);

    // this.setState((prevState) => ({
    //   newItems: [...prevState.newItems, newItem],
    // }));
  };

  const deleteTaskById = (id) => {
    deleteTask({ variables: { id } });
  };

  return (
    <div className="SyncPlan">
      <div className="SyncPlan__title">
        <div className="SyncPlan__title-text">
          Tidsplan for synkronisering med ERP
        </div>
      </div>
      {planCopy.map((time, index) => (
        <SyncPlanItem
          key={time.id}
          time={time}
          index={index}
          dayChangeHandler={dayChangeHandler}
          timeChangeHandler={timeChangeHandler}
          deleteTask={deleteTask}
        />
      ))}
      {newItems.map((time, index) => (
        <SyncPlanItem
          key={time}
          time={time}
          index={index}
          dayChangeHandler={newDayChangeHandler}
          timeChangeHandler={newTimeChangeHandler}
          deleteTask={deleteTaskById}
        />
      ))}
      <div className="SyncSettings__add-button">
        <Button
          buttonStyling="syncAdd"
          label="Tilføj tidspunkt"
          click={addNewSlot}
        />
      </div>
    </div>
  );
};
// class SyncPlan extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       plan: props.plan,
//       newItems: [],
//     };
//   }

//   componentWillReceiveProps(nextProps) {
//     if (nextProps.plan !== this.state.plan) {
//       this.setState({ plan: nextProps.plan });
//     }
//   }

//   async componentWillUnmount() {
//     await this.props.updateSchedule({
//       variables: { schedule: this.state.plan },
//     });
//     await this.props.createSchedule({
//       variables: { schedule: this.state.newItems },
//     });
//   }

//   dayChangeHandler = (input, index) => {
//     const timePlanCopy = [...this.state.plan];

//     timePlanCopy[index].day = input;

//     this.setState({ plan: timePlanCopy });
//   };

//   timeChangeHandler = (input, index) => {
//     const timePlanCopy = [...this.state.plan];

//     timePlanCopy[index].time = input;

//     this.setState({ plan: timePlanCopy });
//   };

//   newDayChangeHandler = (input, index) => {
//     const timePlanCopy = [...this.state.newItems];

//     timePlanCopy[index].day = input;

//     this.setState({ newItems: timePlanCopy });
//   };

//   newTimeChangeHandler = (input, index) => {
//     const timePlanCopy = [...this.state.newItems];

//     timePlanCopy[index].time = input;

//     this.setState({ newItems: timePlanCopy });
//   };

//   addNewSlot = () => {
//     const newItem = { day: 1, time: 1 };

//     this.setState((prevState) => ({
//       newItems: [...prevState.newItems, newItem],
//     }));
//   };

//   deleteTask = (id) => {
//     this.props.deleteTask({ variables: { id } });
//   };

//   render() {
//     const { plan, newItems } = this.state;
//     return (
//       <div className="SyncPlan">
//         <div className="SyncPlan__title">
//           <div className="SyncPlan__title-text">
//             Tidsplan for synkronisering med ERP
//           </div>
//         </div>
//         {plan.map((time, index) => (
//           <SyncPlanItem
//             key={time.id}
//             time={time}
//             index={index}
//             dayChangeHandler={this.dayChangeHandler}
//             timeChangeHandler={this.timeChangeHandler}
//             deleteTask={this.deleteTask}
//           />
//         ))}
//         {newItems.map((time, index) => (
//           <SyncPlanItem
//             key={time}
//             time={time}
//             index={index}
//             dayChangeHandler={this.newDayChangeHandler}
//             timeChangeHandler={this.newTimeChangeHandler}
//             deleteTask={this.deleteTask}
//           />
//         ))}
//         <div className="SyncSettings__add-button">
//           <Button
//             buttonStyling="syncAdd"
//             label="Tilføj tidspunkt"
//             click={() => {
//               this.addNewSlot();
//             }}
//           />
//         </div>
//       </div>
//     );
//   }
// }

// const WrappedSyncPlan = compose(
//   graphql(updateSchedule, {
//     options: {
//       refetchQueries: getSchedule,
//       awaitRefetchQueries: true,
//       update: (cache, { data }) => {
//         const query = cache.readQuery({ query: getSchedule });

//         const s = {
//           ...query.getSchedule,
//           getSchedule: {
//             ...data.updateSchedule,
//           },
//         };

//         cache.writeQuery({ query: getSchedule, data: s });
//       },
//     },
//     name: "updateSchedule",
//   }),
//   graphql(createSchedule, {
//     options: {
//       refetchQueries: getSchedule,
//       awaitRefetchQueries: true,
//       update: (cache, { data }) => {
//         const query = cache.readQuery({ query: getSchedule });

//         const s = {
//           ...query.getSchedule,
//           getSchedule: {
//             ...query.getSchedule,
//             ...data.createSchedule,
//           },
//         };

//         cache.writeQuery({ query: getSchedule, data: s });
//       },
//     },
//     name: "createSchedule",
//   })
// )(SyncPlan);

// export { WrappedSyncPlan as SyncPlan };
