import moment from "moment";
import { FC } from "react";
import { Button } from "../../Components/Button/Button";
import { Icon } from "../../Components/Icon/Icon";

export type EditActionsProps = {
  onSave: () => void;
  saveState: "dirty" | "clean";
  lastUpdateUsername?: string;
  lastUpdatedAt?: string;
  otherActions: JSX.Element | null;
};

export const EditActions: FC<EditActionsProps> = ({
  onSave,
  saveState,
  lastUpdateUsername,
  lastUpdatedAt,
  otherActions,
}) => {
  return (
    <div className="EditActions">
      <div className="EditActions__button">
        <Button label="Gem" buttonStyling="save" click={() => onSave()} />
      </div>
      <div className="EditActions__meta">
        <div className="EditActions__save-icon">
          <Icon
            name={saveState === "dirty" ? "notSaved" : "syncSucces"}
            width={26}
            className=""
            height={26}
            style={{}}
          />
        </div>
        <div className="EditActions__savedBy">
          <div className="EditActions__saved-state">
            {saveState === "dirty" ? "Ikke gemt" : "Ændringer gemt"}
          </div>
          <div className="EditActions__savedBy-name">
            Af: {lastUpdateUsername ?? "SYSTEM"}
          </div>
          <div className="EditActions__savedBy-date">
            {lastUpdatedAt ? moment(lastUpdatedAt).format("L") : "Ukendt"}
          </div>
        </div>
      </div>
      {otherActions && <div className="EditActions__other">{otherActions}</div>}
    </div>
  );
};
