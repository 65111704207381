import { FC } from "react";
import "./TextArea.css";

type Props = {
  placeholder: string;
  value?: string;
  change: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  styling?: "default" | "small";
  readOnly?: boolean;
  rows?: number;
  cols?: number;
};

export const TextArea: FC<Props> = ({
  placeholder,
  value = "",
  change,
  styling = "default",
  readOnly = false,
  rows,
  cols = 1,
}) => {
  return (
    <textarea
      className={`TextArea TextArea--${styling}`}
      placeholder={placeholder}
      value={value}
      onChange={change}
      disabled={readOnly}
      rows={rows}
      cols={cols}
    />
  );
};
