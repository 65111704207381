import React, { FC, PropsWithChildren, useMemo } from "react";
import { Icon } from "../Icon/Icon";
import { SelectedFilter, SelectedFilters, selectedFiltersVar } from "../../App";
import { useReactiveVar } from "@apollo/client";
import classNames from "classnames";

// Establish a type with the label for each filter included
export type SelectedFilterWithLabel = {
  selectedFilter: SelectedFilter;
  label: string;
};

export type Props = {
  selectedFilters: SelectedFilters;
  label: string;
  filters: SelectedFilterWithLabel[];
  selectMultiple?: boolean;
};

export const OverviewFilterGroup: FC<Props> = ({
  selectedFilters: selectedFilter,
  label,
  filters,
  selectMultiple = true,
}) => {
  // Show the reset button if any of the filters are in the selected filter list
  const showResetButton = useMemo(() => {
    return filters.some((filter) => {
      return selectedFilter.some((selectedFilter) => {
        return (
          selectedFilter.type === filter.selectedFilter.type &&
          selectedFilter.value === filter.selectedFilter.value
        );
      });
    });
  }, [filters, selectedFilter]);

  function handleResetClick() {
    // Remove the filters from the current group
    // Ie, if the filter is both in selectedFilters and filters, it should be removed
    const newSelectedFilters = selectedFilter.filter((filter) => {
      return !filters.some((filterWithLabel) => {
        return (
          filter.type === filterWithLabel.selectedFilter.type &&
          filter.value === filterWithLabel.selectedFilter.value
        );
      });
    });

    selectedFiltersVar(newSelectedFilters);
  }

  return (
    <div className="OverviewFilterGroup">
      <div className="OverviewFilterGroup__title">
        <div className="OverviewFilterGroup__title-text">{label}</div>
      </div>
      {showResetButton && (
        <div className="OverviewFilterGroup__reset" onClick={handleResetClick}>
          <div className="OverviewFilterGroup__reset-icon">
            <Icon name="resetNormal" width={20} />
          </div>
          <div className="OverviewFilterGroup__reset-text">Nulstil</div>
        </div>
      )}
      <div className="OverviewFilterGroup__buttons">
        {filters.map((filter) => {
          return (
            <FilterButton
              key={filter.selectedFilter.value}
              value={filter.selectedFilter}
              selectMultiple={selectMultiple}
            >
              {filter.label}
            </FilterButton>
          );
        })}
      </div>
    </div>
  );
};

const FilterButton: FC<
  PropsWithChildren<{ value: SelectedFilter; selectMultiple?: boolean }>
> = ({ value: filterValue, children, selectMultiple = true }) => {
  const selectedFilters = useReactiveVar(selectedFiltersVar);

  // Determine if the button is selected
  const isSelected = useMemo(() => {
    // If no filters are selected, then the button is not selected
    if (selectedFilters.length === 0) {
      return false;
    }

    // If the filter is not in the list of selected filters, then the button is not selected
    return selectedFilters.some((filter) => {
      return (
        filter.type === filterValue.type && filter.value === filterValue.value
      );
    });
  }, [selectedFilters, filterValue]);

  function handleClick() {
    // Remove the filter if the button is selected
    if (isSelected) {
      return removeFilter();
    }

    // Add the filter if the button is not selected according to the selectMultiple prop
    // This controls wether to remove other selections before adding the new one
    if (selectMultiple) {
      return addMultipleFilter();
    } else {
      return addSingleFilter();
    }
  }

  /**
   * Remove the filter from the list of selected filters
   */
  function removeFilter() {
    // Remove the filter from the list of selected filters
    const newSelectedFilters = selectedFilters.filter((filter) => {
      return (
        filter.type !== filterValue.type || filter.value !== filterValue.value
      );
    });

    selectedFiltersVar(newSelectedFilters);
  }

  /**
   * Add the filter to the list of selected filters
   */
  function addMultipleFilter() {
    selectedFiltersVar([...selectedFilters, filterValue]);
  }

  /**
   * Add the filter to the list of selected filters,
   * but first remove all other filters of the same type
   */
  function addSingleFilter() {
    // First remove all filters of the same type
    const newSelectedFilters = selectedFilters.filter(
      (filter) => filter.type !== filterValue.type
    );

    // Then add the new filter
    selectedFiltersVar([...newSelectedFilters, filterValue]);
  }

  return (
    <div
      className={classNames("OverviewFilterGroup__button", {
        "OverviewFilterGroup__button--selected": isSelected,
      })}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
