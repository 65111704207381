import { useHistory } from "react-router-dom";
import "./Login.css";
import { LoginModal } from "../../Components/LoginModal/LoginModal";
import { config } from "../../config";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { useLoginMutation } from "../../Generated/graphql";
import { useState } from "react";

export const Login = () => {
  const history = useHistory();
  const [mutate] = useLoginMutation();
  const [signingIn, setSigningIn] = useState(false);

  const doLogin = async (username: string, password: string) => {
    setSigningIn(true);
    const loginResult = await mutate({
      variables: {
        username,
        password,
      },
    });

    if (loginResult.errors) {
      // Display some kind of error in the form.
      console.error("Could not login with provided credentials");
    }

    if (loginResult.data?.login) {
      setSigningIn(false);

      // Navigate to main page
      history.push("/main");
    }

    setSigningIn(false);
  };

  return (
    <div className="Login">
      {!signingIn ? (
        <LoginModal
          logo={config.logo.appLogoName}
          logoWidth={config.logo.appLogoWidth}
          company={config.company.name}
          logIn={doLogin}
        />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};
