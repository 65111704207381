import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Toolbar.css";
import { Icon } from "../../../Components/Icon/Icon";
import { ToolbarActions } from "./ToolbarActions/ToolbarActions";
import { ValidIcons } from "../../../Components/Icon/Icon-list";
import { useProductQuery } from "../../../Generated/graphql";

type Props = {
  logoName: ValidIcons;
};

export const Toolbar: FC<Props> = ({ logoName }) => {
  const location = useLocation();
  const history = useHistory();

  const split = location.pathname.split("/");
  const pathState = split[split.length - 1];

  const isHomePath = pathState === "main" || pathState === "overview";

  const isEditProduct =
    parseInt(pathState) && location.pathname.includes("edit") ? true : false;

  const isSettingsPage = location.pathname.includes("settings") ? true : false;

  const { data: productData } = useProductQuery({
    variables: {
      productNumber: pathState,
    },
    skip: !isEditProduct,
  });

  return (
    <div className="Toolbar">
      <div className="Toolbar__content">
        <div className="Toolbar__navpath" onClick={() => history.push("/main")}>
          <div className="Toolbar__home">
            {!isHomePath ? (
              <Icon name="homeActive" width={98} height={100} />
            ) : (
              <Icon name="home" width={98} height={100} />
            )}
          </div>
          {isEditProduct && (
            <div className="Toolbar__subpath">
              {productData?.product?.productData?.name}
            </div>
          )}
          {isSettingsPage && (
            <div className="Toolbar__subpath">Indstillinger</div>
          )}
        </div>
        <div className="Toolbar__logo">
          <Icon name={logoName} width={40} />
        </div>
        <div className="Toolbar__actions">
          <ToolbarActions />
        </div>
      </div>
    </div>
  );
};
