import "./Icon.css";
import IconList, { ValidIcons } from "./Icon-list";
import { FC, useMemo } from "react";

type Props = {
  name: ValidIcons;
  width: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
};

export const Icon: FC<Props> = ({
  name,
  width,
  height = width,
  className = "",
  style = {},
}) => {
  const styles = Object.assign({}, style, {
    width: `${width}px`,
    height: `${height}px`,
  });

  const FoundIcon = useMemo(() => {
    return IconList[name];
  }, [name]);

  return (
    <div className={`Icon ${className}`}>
      <FoundIcon style={styles} />
    </div>
  );
};
