import React, { FC, useState } from "react";

import "./AddNewPropertyValuePopup.css";
import { Modal } from "../../Components/Modal/Modal";
import { TextInput } from "../../Components/TextInput/TextInput";

type Props = {
  propertyId: string;
  onAdd: (propertyId: string, value: string) => void;
  onCancel: () => void;
};

export const AddNewPropertyValuePopup: FC<Props> = ({
  propertyId,
  onAdd,
  onCancel,
}) => {
  const [value, setValue] = useState("");

  const primaryButton = {
    text: "Tilføj",
    onClick: () => onAdd(propertyId, value),
  };

  const secondaryButton = {
    text: "Fortryd",
    onClick: () => onCancel(),
  };

  return (
    <Modal
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      className="AddNewPropertyValuePopup"
    >
      <div className="AddNewPropertyValuePopup__Headline">Tilføj ny værdi</div>
      <div className="AddNewPropertyValuePopup__Body">
        <TextInput
          value={value}
          change={(e) => setValue(e.target.value)}
          placeholder="Indtast værdi"
          autofocus
        />
      </div>
    </Modal>
  );
};
