import React, { Component, Fragment, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./Settings.css";
import { Toolbar } from "../Layout/Toolbar/Toolbar";
import { config } from "../../config";
import { Users } from "../Users/Users";
import { SyncSettings } from "../SyncSettings/SyncSettings";
import { SettingsMenu } from "../../Components/SettingsMenu/SettingsMenu";
import { UserEdit } from "../UserEdit/UserEdit";
import { UserNew } from "../UserNew/UserNew";
import { Import } from "../Import/Import";
import { ImportProductData } from "../ImportProductData/ImportProductData";
import { Properties } from "../Properties/Properties";
import { PropertiesNew } from "../PropertiesNew/PropertiesNew";
import { PropertiesEdit } from "../PropertiesEdit/PropertiesEdit";
import { PropertiesValues } from "../PropertiesValues/PropertiesValues";
import { PropertiesValuesNew } from "../PropertiesValuesNew/PropertiesValuesNew";
import { PropertiesValuesEdit } from "../PropertiesValuesEdit/PropertiesValuesEdit";

export class Settings extends Component {
  splitPathname = (location: any) => {
    const splitted = location.pathname.split("/");
    return splitted[splitted.length - 1];
  };

  render() {
    return (
      <div style={{ height: "100%", overflow: "hidden" }}>
        <Toolbar logoName={config.logo.appLogoName}></Toolbar>

        <Route
          render={({ location, history }) => (
            <div className="Settings__wrapper">
              <div className="Settings__menu">
                <SettingsMenu />
              </div>
              <div className="Settings__content">
                <Fragment>
                  <Switch>
                    <Route exact path="/settings/users" component={Users} />
                    <Route
                      exact
                      path="/settings/users/new"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <UserNew />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/users/:id"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <UserEdit />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/properties"
                      component={Properties}
                    />
                    <Route
                      exact
                      path="/settings/properties/new"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <PropertiesNew />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/properties/:id"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <PropertiesEdit />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/properties/:id/values"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <PropertiesValues />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/properties/:id/values/new"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <PropertiesValuesNew />
                        </Suspense>
                      )}
                    />
                    <Route
                      exact
                      path="/settings/properties/:propertyId/values/:id"
                      render={() => (
                        <Suspense
                          fallback={<div>There's bacon in theese woods!</div>}
                        >
                          <PropertiesValuesEdit />
                        </Suspense>
                      )}
                    />
                    <Route path="/settings/sync" component={SyncSettings} />
                    <Route path="/settings/import" component={Import} />
                    <Route
                      path="/settings/importprod"
                      component={ImportProductData}
                    />
                  </Switch>
                  {this.splitPathname(location) === "settings" && (
                    <Redirect to="/settings/users" />
                  )}
                </Fragment>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}
