import { useState, FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../../Components/Icon/Icon";
import { ProgressBar } from "../../Components/ProgressBar/ProgressBar";
import "./OverviewItem.css";
import { GroupProductType, Metadata } from "../../Generated/graphql";
import { QueryProduct, QueryProductVariant } from "./QueryProduct.type";
import classNames from "classnames";
import { calculateVariantPercentageDone } from "../../Utils/calculateVariantPercentageDone";
import { calculateGroupOrSinglePercentageDone } from "../../Utils/calculateGroupPercentageDone";
import { formatProductDescription } from "../../Utils/formatProductDescription";
import { ProductStatus } from "./ProductStatus";

type Props = {
  product: QueryProduct;
  metadata: Metadata[];
  onChangeShowInShop: (args: {
    type: GroupProductType;
    value: boolean;
    productId: string;
  }) => void;
  onImageClick: (imageUrl: string) => void;
};

export const OverviewItem: FC<Props> = ({
  product,
  metadata,
  onChangeShowInShop,
  onImageClick,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const hasVariants = useMemo(() => {
    if (!product?.variants) {
      return false;
    }

    return product.variants.length > 0;
  }, [product.variants]);

  // Get the variants in a clean way
  const variants = useMemo(() => {
    if (!product?.variants) {
      return [];
    }

    return product.variants;
  }, [product.variants]);

  const handleToggleShowInShop = (args: {
    type: GroupProductType;
    value: boolean;
    productId: string;
  }) => {
    onChangeShowInShop(args);
  };

  const handleClickEdit = (product: QueryProduct | QueryProductVariant) => {
    switch (product.type) {
      case GroupProductType.Group:
        history.push(`/main/edit/group/${product.id}`);
        break;
      case GroupProductType.Single:
      case GroupProductType.Variant:
        history.push(`/main/edit/product/${product.productNumber}`);
        break;
    }
  };

  // Toggle the open/closed state of a group with variants
  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const productDescription = useMemo(() => {
    return formatProductDescription({
      prodNo: product.productNumber ?? undefined,
      productData: product.productData,
    });
  }, [product.productData, product.productNumber]);

  const hasImage = useMemo(() => {
    return product.imageUrl !== null && product.imageUrl !== undefined;
  }, [product.imageUrl]);

  return (
    <>
      <div
        className={classNames({
          OverviewItem: true,
          "OverviewItem--hasVariants": hasVariants,
          "OverviewItem--isOpen": isOpen,
        })}
      >
        <div className="OverviewItem__foldout" onClick={toggleOpen}>
          <div className="OverviewItem__caret">
            <Icon name="arrowDown" width={10} />
          </div>
        </div>
        {hasImage && (
          <div
            className="OverviewItem__image u-pointer"
            onClick={() => onImageClick(product.imageUrl ?? "")}
          >
            <img
              loading="lazy"
              className="OverviewItem__image-img"
              src={product.imageUrl ?? ""}
              alt="På vej"
            />
          </div>
        )}
        {!hasImage && (
          <div className="OverviewItem__image">
            <Icon name="noImageAvail" width={60} />
          </div>
        )}
        <div className="OverviewItem__information">
          <div className="OverviewItem__name"> {product.name} </div>
          <div className="OverviewItem__description">{productDescription}</div>
        </div>
        <div className="OverviewItem__product-status">
          <ProductStatus productData={product.productData} />
        </div>
        <div
          className="OverviewItem__webshop-state u-cursor-pointer"
          onClick={() =>
            handleToggleShowInShop({
              type: product.type,
              productId: product.id,
              value: !product.showInShop,
            })
          }
        >
          <div className="OverviewItem__webshop-state-icon">
            <Icon
              name={
                product.showInShop ? "visibilityVisible" : "visibilityHidden"
              }
              width={28}
            />
          </div>
          <div className="OverviewItem__webshop-state-text">
            {product.showInShop ? "Aktiv" : "Skjult"}
          </div>
        </div>
        <div className="OverviewItem__doneness">
          <ProgressBar
            name=""
            min={0}
            max={100}
            current={
              product.type === GroupProductType.Variant
                ? calculateVariantPercentageDone(
                    metadata,
                    product
                  ).getPercentage()
                : calculateGroupOrSinglePercentageDone(
                    metadata,
                    product
                  ).getPercentage()
            }
          />
        </div>
        <div
          className="OverviewItem__edit"
          onClick={() => handleClickEdit(product)}
        >
          <Icon
            name="editHover"
            width={32}
            className="OverviewItem__editIconHover"
          />
          <Icon
            name="editNormal"
            width={32}
            className="OverviewItem__editIconNormal"
          />
        </div>
      </div>
      {isOpen &&
        variants.map((variant) => (
          <div
            className={classNames({
              OverviewItem: true,
              "OverviewItem--isVariant": true,
            })}
            key={variant.id}
          >
            {variant.imageUrl && (
              <div
                className="OverviewItem__image u-pointer"
                onClick={() => onImageClick(variant.imageUrl ?? "")}
              >
                <img
                  loading="lazy"
                  className="OverviewItem__image-img"
                  src={variant.imageUrl}
                  alt="På vej"
                />
              </div>
            )}
            {!variant.imageUrl && (
              <div className="OverviewItem__image">
                <Icon name="noImageAvail" width={60} />
              </div>
            )}

            <div className="OverviewItem__information">
              <div className="OverviewItem__name"> {variant.name} </div>
              <div className="OverviewItem__description">
                {formatProductDescription({
                  prodNo: variant.productNumber,
                  productData: variant.productData,
                })}
              </div>
            </div>
            <div className="OverviewItem__product-status">
              <ProductStatus productData={variant.productData} />
            </div>
            <div
              className="OverviewItem__webshop-state"
              onClick={() =>
                handleToggleShowInShop({
                  type: variant.type,
                  productId: variant.id,
                  value: !variant.showInShop,
                })
              }
            >
              <div className="OverviewItem__webshop-state-icon">
                <Icon
                  name={
                    variant.showInShop
                      ? "visibilityVisible"
                      : "visibilityHidden"
                  }
                  width={28}
                />
              </div>
              <div className="OverviewItem__webshop-state-text">
                {variant.showInShop ? "Vis i shop" : "Skjul i shop"}
              </div>
            </div>
            <div className="OverviewItem__doneness">
              <ProgressBar
                name=""
                min={0}
                max={100}
                current={calculateVariantPercentageDone(
                  metadata,
                  variant
                ).getPercentage()}
              />
            </div>
            <div
              className="OverviewItem__edit"
              onClick={() => handleClickEdit(variant)}
            >
              <Icon
                name="editHover"
                width={32}
                className="OverviewItem__editIconHover"
              />
              <Icon
                name="editNormal"
                width={32}
                className="OverviewItem__editIconNormal"
              />
            </div>
          </div>
        ))}
    </>
  );
};
