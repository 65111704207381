import React, { FC } from "react";

import "./EditSpecification.css";
import { TextInput } from "../TextInput/TextInput";
import classNames from "classnames";

export type Specification = {
  sorting: number;
  key: string;
  value: string;
};

type Props = {
  spec: Specification;
  isDragging: boolean;
  updateInput: (newValue: Specification) => void;
  readonly?: boolean;
};

export const EditSpecification: FC<Props> = ({
  spec,
  isDragging,
  updateInput,
  readonly = false,
}) => {
  return (
    <div
      className={classNames("EditSpecification", {
        "EditSpecification--readonly": readonly,
      })}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="EditSpecification__key">
        <TextInput
          value={spec.key}
          change={(e) => updateInput({ ...spec, key: e.target.value })}
          readOnly={readonly}
        />
      </div>
      <div className="EditSpecification__border" />
      <div className="EditSpecification__value">
        <TextInput
          value={spec.value}
          change={(e) => updateInput({ ...spec, value: e.target.value })}
          readOnly={readonly}
        />
      </div>
    </div>
  );
};
