import { FC } from "react";
import { noop } from "../../Utils/noop";
import { ModalLarge } from "../ModalLarge/ModalLarge";
import {
  useLightbox,
  useLightboxDispatch,
} from "../../Containers/Main/LightboxContext";

import "./Lightbox.css";

type Props = {
  imageUrl?: string;
  onClose?: () => void;
};

export const Lightbox: FC<Props> = ({ imageUrl, onClose = noop }) => {
  const downloadImage = () => {
    if (!imageUrl) {
      return;
    }

    const inferredFilename = imageUrl?.split("/").pop();

    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = inferredFilename ?? "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <ModalLarge
        className="Lightbox"
        primaryButton={{ text: "Download", onClick: downloadImage }}
        secondaryButton={{ text: "Luk", onClick: onClose }}
      >
        <img src={imageUrl} alt="" className="Lightbox__Image" />
      </ModalLarge>
    </>
  );
};

export const LightboxWithProvider = () => {
  const { isOpen, imageUrl } = useLightbox();
  const dispatch = useLightboxDispatch();

  const handleOnClose = () => {
    dispatch({ type: "CLOSE_POPUP" });
  };

  return isOpen ? (
    <Lightbox imageUrl={imageUrl} onClose={handleOnClose} />
  ) : (
    <></>
  );
};
