import { FC } from "react";

import "./DropFiles.css";
import { useDropzone } from "react-dropzone";
import { Icon } from "../Icon/Icon";
import classNames from "classnames";

type Props = {
  theme?: "medium" | "tall" | "variant-upload";
  onDrop: (files: File[]) => void;
};

export const DropFiles: FC<Props> = ({ theme = "medium", onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={classNames({
        DropFiles: true,
        [`DropFiles--theme-${theme}`]: true,
        "DropFiles--dropActive": isDragActive,
      })}
    >
      <div {...getRootProps()} className="DropFiles__dropzone">
        <input {...getInputProps()} type="file" />
        <div className="DropFiles__icon">
          <Icon
            name={isDragActive ? "uploadActive" : "uploadInactive"}
            width={24}
          />
        </div>
        <div className="DropFiles__text">
          Træk hertil for at uploade, eller <u>vælg fil her</u>
        </div>
      </div>
    </div>
  );
};
