import "./ToolbarActions.css";
import { Icon } from "../../../../Components/Icon/Icon";
import { useApolloClient } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

export const ToolbarActions = () => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const path = location.pathname.split("/");
  const middlePart = path[1];

  return (
    <div className="ToolbarActions">
      <div
        className="ToolbarActions__settings"
        style={{ opacity: middlePart !== "settings" ? 0.6 : 1 }}
        onClick={() => history.push("/settings")}
      >
        <Icon name="settings" width={22} />
      </div>
      <div
        className="ToolbarActions__logout"
        style={{ opacity: 0.6 }}
        onClick={() => {
          localStorage.removeItem("bdjToken");
          client.resetStore();
          history.push("/login");
        }}
      >
        <Icon name="logout" width={16} height={19} />
      </div>
    </div>
  );
};
