import { FC } from "react";

import "./ProductStatus.css";
import { getProductStatusConfig } from "../../Utils/getProductStatusConfig";
import classNames from "classnames";

type Props = {
  productData: {
    status: {
      statusText: string;
      statusCode: string;
    };
  } | null;
  theme?: "full" | "circle";
};

// A product status component, that shows a circle representing the status of the product and a status text beside it. Use camelCase for the class names
export const ProductStatus: FC<Props> = ({ productData, theme = "full" }) => {
  if (!productData?.status?.statusCode || !productData?.status?.statusText) {
    return <></>;
  }

  const { status } = productData;

  const statusConfig = getProductStatusConfig(status);

  return (
    <div
      className={classNames("ProductStatus", `ProductStatus--theme-${theme}`)}
      title={`${status.statusText} (${status.statusCode})`}
    >
      <div
        className="ProductStatus__Indicator"
        style={{ color: statusConfig.color }}
      ></div>
      <div className="ProductStatus__Text">{statusConfig.textOverride}</div>
    </div>
  );
};
