import React, { FC, useState } from "react";
import moment from "moment-timezone";

import "./Sync.css";
import { Icon } from "../Icon/Icon";
import triggerSync from "../../GraphQL/Mutations/triggerSync";
import { useMutation } from "@apollo/client";
import { useLastSyncTimeQuery } from "../../Generated/graphql";

type Props = {
  status?: boolean;
};

export const Sync: FC<Props> = ({ status = true }) => {
  const [syncing, setSyncing] = useState(false);
  const { data, error, loading } = useLastSyncTimeQuery();
  const [triggerSyncAction] = useMutation(triggerSync);
  if (loading) return <div />;
  return (
    <div className="Sync">
      <div className="Sync__status">
        <div className="Sync__status-icon">
          <Icon name="syncSucces" width={26} />
        </div>
        <div className="Sync__status-text">
          <div className="Sync__status-title">
            {status ? "Synkroniseret" : "Fejl"}
          </div>
          <div className="Sync__status-subtitle">
            {!error && data && data.lastSyncTime
              ? moment(data.lastSyncTime).format("LLL")
              : ""}
          </div>
        </div>
      </div>
      <div
        className="Sync__button"
        onClick={() => {
          setSyncing(true);
          triggerSyncAction().catch((err) => console.log("Error:", err));
          setTimeout(() => {
            setSyncing(false);
          }, 360000);
        }}
      >
        <div className={syncing ? "Sync__icon-animation" : ""}>
          <Icon name="syncNormal" width={28} />
        </div>
        <div className="Sync__button-label">Opdater</div>
      </div>
    </div>
  );
};
