import { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";

import "./PropertiesEdit.css";
import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";
import {
  usePropertiesEditMutation,
  usePropertiesEditQueryQuery,
} from "../../Generated/graphql";

const PropertiesEdit = () => {
  const [label, setLabel] = useState("");

  const match = useRouteMatch<{ id: string }>();

  const [updatePropertyMutation] = usePropertiesEditMutation();

  const id = match.params.id;

  const history = useHistory();
  const query = usePropertiesEditQueryQuery({
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  const isValid = useMemo(() => {
    return label.length >= 3;
  }, [label]);

  const onSave = () => {
    updatePropertyMutation({
      variables: {
        id,
        label,
      },
    }).then(() => {
      history.goBack();
    });
  };

  // Update state when user is loaded
  useEffect(() => {
    if (!query.data) {
      return;
    }

    setLabel(query.data.property.label);
  }, [query]);

  if (query.loading) return <div>Loading...</div>;

  if (query.error) return <div>{query.error.message}</div>;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
      path: "/settings/properties",
    },
    {
      name: query.data?.property.label ?? "",
    },
  ];

  return (
    <SettingsForm
      onSave={onSave}
      breadcrumbs={breadcrumbs}
      isButtonActive={isValid}
      theme="slim"
    >
      <div>
        <FormElement label="Navn">
          <TextInput
            value={label}
            change={(e: any) => {
              setLabel(e.target.value);
            }}
            placeholder="Navn"
          />
        </FormElement>
      </div>
    </SettingsForm>
  );
};

const routed = withRouter(PropertiesEdit);

export { routed as PropertiesEdit };
