import React from "react";

import "./Loading.css";
import { Icon } from "../Icon/Icon";

export const Loading = () => {
  return (
    <div className="Loading">
      <div className="Loading__spin">
        <Icon name="syncLoading" width={68} height={68} />
      </div>
    </div>
  );
};
