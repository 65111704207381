import React, { FC } from "react";

import "./ProgressBar.css";
import { useSpring, animated, config } from "react-spring";

type Props = {
  current: number;
  min: number;
  max: number;
  name: string;
};

export const ProgressBar: FC<Props> = ({ current, min, max, name }) => {
  const correctedCurrent = current > max ? max : current;
  const anim = useSpring({
    from: { width: "0%" },
    to: { width: (100 / max) * correctedCurrent + "%" },
    reset: false,
    delay: 600,
    config: config.gentle,
  });

  return (
    <div className="ProgressBar">
      {correctedCurrent < min ? (
        <animated.div
          className="ProgressBar__bar"
          style={{ width: anim.width, backgroundColor: "#AA2525" }}
        ></animated.div>
      ) : correctedCurrent < (max / 100) * 35 ? (
        <animated.div
          className="ProgressBar__bar"
          style={{ width: anim.width, backgroundColor: "#D69D23" }}
        ></animated.div>
      ) : (
        <animated.div
          className="ProgressBar__bar"
          style={{ width: anim.width, backgroundColor: "#00D154" }}
        ></animated.div>
      )}
    </div>
  );
};
