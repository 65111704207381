import React, { FC } from "react";

import "./EditFile.css";
import { TextInput } from "../TextInput/TextInput";
import { Icon } from "../Icon/Icon";

export type File = {
  url: string;
  name: string;
  description: string;
  sorting: number;
};

type Props = {
  value: File;
  isDragging: boolean;
  onChange: (newValue: File) => void;
};

export const EditFile: FC<Props> = ({ value, isDragging, onChange }) => {
  const openFile = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="EditFile" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="EditFile__File">
        <div className="EditFile__FileIcon">
          <Icon name="files" width={13} height={18} />
        </div>
        <div className="EditFile__FileName" onClick={() => openFile(value.url)}>
          {value.name}
        </div>
      </div>
      <div className="EditFile__Border" />
      <div className="EditFile__Description">
        <TextInput
          value={value.description}
          change={(e) => onChange({ ...value, description: e.target.value })}
          placeholder="Beskrivelse..."
        />
      </div>
    </div>
  );
};
