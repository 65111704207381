import { FC, useCallback, useEffect, useMemo } from "react";
import { Loading } from "../../Components/Loading/Loading";
import {
  useEditProductQuery,
  useUpdateProductMutation,
} from "../../Generated/graphql";
import "./Edit.css";
import { isNull, isUndefined } from "lodash";
import { ProductFields } from "./ProductFields";
import { EditActions } from "./EditActions";
import { StateButton } from "./StateButton";
import { useEditProductOrGroup } from "./useEditProductOrGroup";
import { editNavigationVar } from "../../App";

type Props = {
  productId: string;
};

/**
 * Screen to update a product - ie. a Single or a Variant of a Variant Group
 */
export const EditProduct: FC<Props> = ({ productId }) => {
  // Fetch the current values
  const { loading, error, data, refetch } = useEditProductQuery({
    variables: { productNumber: productId },
    fetchPolicy: "no-cache", // Avoid bug when navigating to new products
  });

  // Set up the mutation needed for saving data
  const [updateProductMutation] = useUpdateProductMutation();

  const {
    customData,
    setCustomData,
    showInShop,
    handleChangeShowInShop,
    category,
    hasCustomDataChanged,
    hasShowInShopChanged,
    isDirty,
  } = useEditProductOrGroup({
    initialCustomData: data?.product?.customData ?? null,
    initialProductData: data?.product?.productData ?? null,
    initialShowInShop: data?.product?.showInShop ?? null,
  });

  const productNumber = useMemo(() => {
    return data?.product?.prodNo ?? null;
  }, [data?.product?.prodNo]);

  const handleSave = useCallback(async () => {
    // Just a sanity check to avoid saving null data
    if (isNull(customData) || isNull(productNumber)) {
      alert("Kunne ikke gemme produktet. Prøv igen senere.");
      return;
    }

    // Only update the fields that have changed
    const dataToUpdate = {
      ...{ customData: hasCustomDataChanged ? customData : undefined },
      ...{ showInShop: hasShowInShopChanged ? showInShop : undefined },
    };

    // Update the product
    await updateProductMutation({
      variables: { prodNo: productNumber, ...dataToUpdate },
    });

    // Refetch the product
    await refetch();
  }, [
    customData,
    hasCustomDataChanged,
    hasShowInShopChanged,
    productNumber,
    refetch,
    showInShop,
    updateProductMutation,
  ]);

  // Set the navigation var to properly highlight the selected product
  useEffect(() => {
    // We need both the category and the product number to set the navigation var
    if (category === null || productNumber === null) {
      return;
    }

    // Set the navigation var
    editNavigationVar({
      selectedCategory: category,
      selectedProductNumber: productNumber,
    });
  }, [category, productNumber]);

  if (
    loading ||
    error ||
    isUndefined(data) ||
    isUndefined(data.product) ||
    isNull(data.product)
  ) {
    return <Loading />;
  }

  return (
    data.product &&
    data.metadata && (
      <div className="Edit__content-wrapper">
        <div className="Edit__content">
          <div className="Edit__content-inner">
            <ProductFields
              customData={customData ?? {}}
              customDataChanged={(customData) => setCustomData(customData)}
              metadata={data.metadata}
              productData={data.product.productData}
              groupProductType={data.product.type}
            />
            {/* <Properties product={data.product} /> */}
          </div>
        </div>
        <div className="Edit__actions">
          <EditActions
            onSave={() => handleSave()}
            saveState={isDirty ? "dirty" : "clean"}
            lastUpdateUsername={data.product.updated_by ?? undefined}
            lastUpdatedAt={data.product.updated_at ?? undefined}
            otherActions={
              <>
                {showInShop !== null && (
                  <StateButton
                    click={handleChangeShowInShop}
                    state={showInShop}
                  />
                )}
              </>
            }
          />
        </div>
      </div>
    )
  );
};
