import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";

import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";
import {
  usePropertiesValuesNewMutation,
  usePropertiesValuesNewQueryQuery,
} from "../../Generated/graphql";

const PropertiesValuesNew = () => {
  const history = useHistory();

  const match = useRouteMatch<{ id: string }>();

  const [isValid, setIsValid] = useState(false);
  const [createPropertyValue] = usePropertiesValuesNewMutation();
  const { data: propertyData } = usePropertiesValuesNewQueryQuery({
    variables: {
      id: match.params.id,
    },
  });

  const [value, setValue] = useState("");

  function save() {
    createPropertyValue({
      variables: {
        propertyId: match.params.id,
        value,
      },
    }).then(() => {
      history.push(`/settings/properties/${match.params.id}/values`);
    });
  }

  // Ensure we have a valid label
  useEffect(() => {
    setIsValid(value.length >= 3);
  }, [value]);

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
      path: "/settings/properties",
    },
    {
      name: propertyData?.property.label ?? "",
      path: `/settings/properties/${propertyData?.property.id}/values`,
    },
    {
      name: "Ny værdi",
    },
  ];

  return (
    <SettingsForm
      onSave={save}
      isButtonActive={isValid}
      breadcrumbs={breadcrumbs}
      buttonLabel="Opret"
      theme="slim"
    >
      <FormElement label="Værdi">
        <TextInput
          value={value}
          change={(e) => {
            setValue(e.target.value);
          }}
        />
      </FormElement>
    </SettingsForm>
  );
};

const routed = withRouter(PropertiesValuesNew);

export { routed as PropertiesValuesNew };
