import gql from "graphql-tag";

export default gql`
  mutation updateSchedule( $schedule: JSON ) {
    updateSchedule( schedule: $schedule ) {
      id
      day
      time
    }
  }
`