import gql from "graphql-tag";

export default gql`
  mutation createSchedule( $schedule: JSON ) {
    createSchedule( schedule: $schedule ) {
      id
      day
      time
    }
  }
`