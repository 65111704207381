import { FC, useState } from "react";
import { ConfirmModal } from "../../Components/ConfirmModal/ConfirmModal";
import { FormElement } from "../../Components/FormElement/FormElement";
import { TextArea } from "../../Components/TextArea/TextArea";
import { TextInput } from "../../Components/TextInput/TextInput";
import { isString } from "lodash";
import { EditSpecifications } from "../../Components/EditSpecifications/EditSpecifications";
import { EditFiles } from "../../Components/EditFiles/EditFiles";
import { EditImages } from "../../Components/EditImages/EditImages";
import {
  GroupProductType,
  Metadata,
  MetadataVisiblityType,
} from "../../Generated/graphql";
import { WYSIWYG } from "../../Components/WYSIWYG/WYSIWYG";
import { DropdownMetaField } from "./DropdownMetaField";
import _ from "lodash";
import { DropdownEditable } from "../../Components/DropdownEditable/DropdownEditable";
import { useLightboxDispatch } from "../Main/LightboxContext";

type ProductFieldsProps = {
  metadata: Pick<
    Metadata,
    "id" | "productDataKey" | "title" | "type" | "visibility" | "jsonPath"
  >[];
  productData: Record<string, any>;
  customData: Record<string, any>;
  groupProductType: GroupProductType;
  customDataChanged: (customData: Record<string, any>) => void;
};

/**
 * This component is responsible for rendering the fields for the product
 * It operates on the customData object, which is a key-value store of the custom data
 */
export const ProductFields: FC<ProductFieldsProps> = ({
  metadata,
  productData,
  customData,
  groupProductType,
  customDataChanged,
}) => {
  const [confirmDialogState, setConfirmDialogState] = useState<{
    confirm: () => void;
    cancel: () => void;
  } | null>(null);

  const dispatchLightbox = useLightboxDispatch();

  /** Sanitize empty string values to null */
  const sanitizeValue = (value: any) => {
    if (!isString(value)) {
      return value;
    }

    return value.trim() === "" ? null : value;
  };

  const changeValue = (value: any, key: string) => {
    const newCustomData = { ...customData, [key]: sanitizeValue(value) };

    customDataChanged(newCustomData);
  };

  /**
   * Found a way to delay the delete action until the user has confirmed the delete
   * We add the confirm and cancel functions to the state,
   * and then call them when the user responds to the modal
   */
  const handleBeforeDelete = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      setConfirmDialogState({
        // Honestly the null here is just required for the type system
        confirm: () => resolve(null),
        cancel: () => reject(),
      });
    }).finally(() => {
      setConfirmDialogState(null);
    });
  };

  const handleImageClick = (imageUrl: string) => {
    dispatchLightbox({ type: "OPEN_POPUP", imageUrl });
  };

  const fields = metadata.map((meta) => {
    // The values from the ERP system
    const productDataValue = productData[meta.productDataKey];

    // The manually created or overridden values
    const customDataValue = customData[meta.productDataKey];

    // Get the visibility for the field, given the current product type
    const fieldVisibility = getFieldVisibility(
      meta.visibility,
      groupProductType
    );

    switch (meta.type) {
      case "IMAGES":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <EditImages
              value={customDataValue ?? []}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
              onBeforeDelete={handleBeforeDelete}
              onImageClick={handleImageClick}
            />
          </FormElement>
        );

      case "FILES":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <EditFiles
              value={customDataValue ?? []}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
              onBeforeDelete={handleBeforeDelete}
            />
          </FormElement>
        );

      case "TEXT_LINE":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <TextInput
              placeholder={productDataValue}
              value={customDataValue ?? ""}
              change={(e) => changeValue(e.target.value, meta.productDataKey)}
              blur={() => {}}
              keyPressed={() => {}}
              readOnly={fieldVisibility === MetadataVisiblityType.Disabled}
            />
          </FormElement>
        );

      case "TEXTBOX_SMALL":
      case "TEXTBOX_MEDIUM":
      case "TEXTBOX_LARGE":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <TextArea
              placeholder={productDataValue}
              value={customDataValue ?? ""}
              change={(e) => changeValue(e.target.value, meta.productDataKey)}
              rows={
                meta.type === "TEXTBOX_SMALL"
                  ? 3
                  : meta.type === "TEXTBOX_MEDIUM"
                  ? 7
                  : 10
              }
              readOnly={fieldVisibility === MetadataVisiblityType.Disabled}
              cols={3}
            />
          </FormElement>
        );

      case "SPECS":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <EditSpecifications
              key={meta.id}
              value={customDataValue ?? []}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
              onBeforeDelete={handleBeforeDelete}
              readonly={fieldVisibility === MetadataVisiblityType.Disabled}
            />
          </FormElement>
        );

      case "WYSIWYG":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <WYSIWYG
              value={customDataValue ?? ""}
              disabled={fieldVisibility === MetadataVisiblityType.Disabled}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
            />
          </FormElement>
        );

      case "DROPDOWN":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <DropdownMetaField
              value={customDataValue ?? productDataValue ?? ""}
              metadataId={meta.id}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
              disabled={fieldVisibility === MetadataVisiblityType.Disabled}
            />
          </FormElement>
        );

      case "DROPDOWN_EDITABLE":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <DropdownEditable
              value={customDataValue ?? productDataValue ?? ""}
              metadataId={meta.id}
              onChange={(newValue) =>
                changeValue(newValue, meta.productDataKey)
              }
              disabled={fieldVisibility === MetadataVisiblityType.Disabled}
            />
          </FormElement>
        );

      case "READONLY_JSON":
        return (
          <FormElement label={meta.title} key={meta.id}>
            <TextInput
              value={getReadonlyJSONValue(
                productDataValue,
                meta.jsonPath ?? ""
              )}
              readOnly
            />
          </FormElement>
        );

      default:
        return null;
    }
  });

  return (
    <>
      {fields}
      {confirmDialogState && (
        <ConfirmModal
          respond={(doIt: boolean) => {
            doIt ? confirmDialogState.confirm() : confirmDialogState.cancel();
          }}
        />
      )}
    </>
  );
};

const getFieldVisibility = (
  visibility: Metadata["visibility"],
  groupProductType: GroupProductType
) => {
  switch (groupProductType) {
    case GroupProductType.Group:
      return visibility.group;
    case GroupProductType.Single:
      return visibility.single;
    case GroupProductType.Variant:
      return visibility.variant;
  }
};

/**
 * Format the output of a JSON field by getting the value at the given path
 */
const getReadonlyJSONValue = (json: any, path: string, defaultValue = "") => {
  return _.get(json, path, defaultValue);
};
