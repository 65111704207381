import React, { useState } from 'react';
import { animated, useSpring, config } from 'react-spring';

import './SpinAndDone.css'
import { Icon } from '../Icon/Icon';

export const SpinAndDone = () => {
  const [done, setDone] = useState(false)
  const props = useSpring({
    from: { transform: 'rotateZ(0deg)' },
    to: async next => {
      if (!done) {
        await next({ transform: 'rotateZ(360deg)' })
        await next({ transform: 'rotateZ(720deg)' })
        await next({ transform: 'rotateZ(1080deg)' })
      }
    },
    config: config.molasses
  })

  setTimeout(() => setDone(true), 1300);

  return (
    <div className="SpinAndDone">
      { !done ? <div className="SpinAndDone__text"> Indlæser </div> : <div className="SpinAndDone__text"> Indlæst </div> }
      <animated.div className="SpinAndDone__loader" style={props}>
        {!done ? <Icon name="syncLoading" width={30} /> :
          <Icon name="syncSucces" width={30} />}
      </animated.div>
    </div>
  )
}