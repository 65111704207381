import { FC, useMemo } from "react";
import { noop } from "../../Utils/noop";

import "./OverviewBtn.css";

type Props = {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
};

export const OverviewBtn: FC<Props> = ({ title, icon, onClick = noop }) => {
  const iconComponent = useMemo(() => {
    if (!icon) {
      return <></>;
    }

    return <div className="OverviewBtn__Icon">{icon}</div>;
  }, [icon]);

  return (
    <div className="OverviewBtn" onClick={onClick}>
      {iconComponent}
      <div className="OverviewBtn__Title">{title}</div>
    </div>
  );
};
