import { useState } from "react";
import { animated, useSpring } from "react-spring";

import "./LoadingComponent.css";

export const LoadingComponent = () => {
  const [done, setDone] = useState(false);
  const { x, opacity } = useSpring({
    from: { x: 180, opacity: 0.1 },
    to: { x: 0, opacity: 1 },
    config: { duration: 700 },
    onRest: () => {
      setDone(!done);
    },
    delay: done ? 400 : 0,
    reverse: done,
    reset: true,
    force: true,
  });

  return (
    <div className="LoadingComponent">
      <div className="LoadingComponent__circle">
        <animated.div style={{ opacity }}>
          <animated.svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            // height="109"
            viewBox="0 0 93 109"
            fill="none"
            stroke="url(#logo-path-b)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray={190}
            strokeDashoffset={x}
          >
            <defs>
              <linearGradient
                id="logo-path-a"
                x1="0%"
                x2="65.257%"
                y1="45.322%"
                y2="71.479%"
              >
                <stop offset="0%" stopColor="#00F260" />
                <stop offset="100%" stopColor="#0575E6" />
              </linearGradient>
              <linearGradient
                id="logo-path-b"
                x1="0%"
                x2="65.257%"
                y1="46.929%"
                y2="64.099%"
              >
                <stop offset="0%" stopColor="#00F260" />
                <stop offset="100%" stopColor="#0575E6" />
              </linearGradient>
            </defs>
            <g fill="none" strokeWidth="7" transform="translate(4 4)">
              <ellipse
                cx="42.5"
                cy="13"
                stroke="url(#logo-path-a)"
                rx="42.5"
                ry="13"
              />
              <path d="M7.10542736e-15 30L7.10542736e-15 38.0647928C7.10542736e-15 45.2444945 19.0278981 51.0647928 42.5 51.0647928 65.9721019 51.0647928 85 45.2444945 85 38.0647928M7.10542736e-15 55L7.10542736e-15 63.0647928C7.10542736e-15 70.2444945 19.0278981 76.0647928 42.5 76.0647928 65.9721019 76.0647928 85 70.2444945 85 63.0647928M7.10542736e-15 80L7.10542736e-15 88.0647928C7.10542736e-15 95.2444945 19.0278981 101.064793 42.5 101.064793 65.9721019 101.064793 85 95.2444945 85 88.0647928" />
            </g>
          </animated.svg>
        </animated.div>
      </div>
    </div>
  );
};
