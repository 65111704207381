import { FC, useState } from "react";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Icon } from "../Icon/Icon";
import { ValidIcons } from "../Icon/Icon-list";

import "./SettingsListItem.css";
import { noop } from "../../Utils/noop";

type Props = {
  name: string;
  label?: string;
  onClick?: () => void;
  iconClick?: () => void;
  deleteClick?: () => void;
};

export const SettingsListItem: FC<Props> = ({
  onClick,
  deleteClick,
  iconClick,
  label,
  name,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [currentIcon, setCurrentIcon] = useState<ValidIcons>("editNormal");

  const handleDeleteConfirm = (ok: boolean) => {
    if (ok && deleteClick) {
      deleteClick();
    }

    setShowDelete(false);
  };

  const showIcon = iconClick !== undefined;
  const showDeleteIcon = deleteClick !== undefined;

  return (
    <div className="SettingsListItem">
      {showDelete && <ConfirmModal respond={handleDeleteConfirm} />}
      <div className="SettingsListItem__block">
        <div
          className="SettingsListItem__name"
          style={{ cursor: onClick ? "pointer" : "default" }}
          onClick={onClick ?? noop}
        >
          {name}
        </div>
        <div className="SettingsListItem__label">{label}</div>
        {showIcon && (
          <div
            className="SettingsListItem__edit"
            onMouseEnter={() => setCurrentIcon("editHover")}
            onMouseLeave={() => setCurrentIcon("editNormal")}
            onClick={iconClick}
          >
            <Icon name={currentIcon} width={32} />
          </div>
        )}
      </div>
      {showDeleteIcon && (
        <div
          className="SettingsListItem__delete"
          onClick={() => setShowDelete(true)}
        >
          <Icon name="deleteNormal" width={9} height={14} />
        </div>
      )}
    </div>
  );
};
