import { FC, Suspense } from "react";
import { EditCategoryItems } from "./EditCategoryItems";
import { Loading } from "../../Components/Loading/Loading";

import "./EditCategory.css";

type Props = {
  /** The id of a category - note that this is also used for the headline */
  categoryId: string;
  /** The product number for a variant or the UUID of a group */
  selectedProductId: string;
};

export const EditCategory: FC<Props> = ({ categoryId, selectedProductId }) => {
  return (
    <div className="EditCategory">
      <div className="EditCategory__title">
        <div className="EditCategory__title-text">{categoryId}</div>
      </div>
      <div className="EditCategory__items">
        <Suspense fallback={<Loading />}>
          <EditCategoryItems
            category={categoryId}
            activeItemId={selectedProductId}
          />
        </Suspense>
      </div>
    </div>
  );
};
