import { FC, useMemo, useState } from "react";

import "./VariantGroupNew.css";
import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import {
  useCreateVariantGroupMutation,
  useVariantGroupNewQuery,
} from "../../Generated/graphql";
import { Select, SelectOption } from "../../Components/Select/Select";
import { isString } from "lodash";
import { Button } from "../../Components/Button/Button";
import { useHistory } from "react-router-dom";

export const VariantGroupNew: FC<{}> = () => {
  const history = useHistory();

  const { data: categoriesData } = useVariantGroupNewQuery();

  const [createVariantGroup] = useCreateVariantGroupMutation();

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  // Validate the form
  const isFormValid = useMemo(() => {
    const nameIsLongEnough = name.length > 3;
    const categoryIsValid = categoriesData?.categories.includes(category);

    return nameIsLongEnough && categoryIsValid;
  }, [name.length, categoriesData?.categories, category]);

  // Construct data for the category selector
  const categories = useMemo(() => {
    if (!categoriesData) {
      return [];
    }

    return (
      categoriesData.categories
        // Since GraphQL returns null values, we need to filter them out
        .filter(isString)
        // Format as SelectOptions
        .map((category) => ({
          label: category,
          key: category,
          value: category,
        }))
    );
  }, [categoriesData]);

  const handleSave = async () => {
    const result = await createVariantGroup({
      variables: {
        name,
        category,
      },
    });

    const newGroupId = result.data?.createVariantGroup?.id;

    if (newGroupId) {
      history.push(`/main/edit/group/${newGroupId}`);
    }
  };

  return (
    <div className="VariantGroupNew">
      <div className="VariantGroupNew__Form">
        <div className="VariantGroupNew__Headline">Opret variantgruppe</div>
        <FormElement label="Navn">
          <TextInput
            placeholder="Navn på produktgruppen"
            value={name}
            change={(e) => setName(e.target.value)}
          />
        </FormElement>
        <FormElement label="Kategori">
          <Select
            defaultText="Vælg en kategori"
            change={(v) => {
              setCategory(v);
            }}
            value={category}
            type="default"
            showFilter
          >
            {categories.map(({ key, label, value }) => (
              <SelectOption key={key} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </FormElement>
        <div className="VariantGroupNew__Button">
          <Button
            buttonStyling="save"
            label="Opret variantgruppe"
            click={() => isFormValid && handleSave()}
            active={isFormValid}
          />
        </div>
      </div>
    </div>
  );
};
