import React, { FC, useState } from "react";
import { animated, useSpring } from "react-spring";

import "./ImportUploadDisplay.css";
import { Icon } from "../Icon/Icon";

type Props = {
  images: string[];
};

export const ImportUploadDisplay: FC<Props> = ({ images }) => {
  return (
    <div className="ImportUploadDisplay">
      <div className="ImportUploadDisplay__title">Importerede billeder</div>
      <div className="ImportUploadDisplay__content">
        {images.map((image, index) => (
          <ImportUploadDisplayItem key={image + "-" + index} image={image} />
        ))}
      </div>
    </div>
  );
};

const ImportUploadDisplayItem: FC<{ image: string }> = ({ image }) => {
  const [done, setDone] = useState(false);
  const props = useSpring({
    from: { transform: "rotateZ(0deg)" },
    to: async (next) => {
      if (!done) {
        await next({ transform: "rotateZ(360deg)" });
        await next({ transform: "rotateZ(720deg)" });
        await next({ transform: "rotateZ(1080deg)" });
      }
    },
  });

  const imageName = image.split("/");

  setTimeout(() => setDone(true), 1300);

  return (
    <div className="ImportUploadDisplayItem">
      <animated.div className="ImportUploadDisplayItem__loader" style={props}>
        {!done ? (
          <Icon name="syncLoading" width={30} />
        ) : (
          <Icon name="syncSucces" width={30} />
        )}
      </animated.div>
      {image && (
        <img
          className="ImportUploadDisplayItem__image"
          src={image}
          alt={imageName[imageName.length - 1]}
        />
      )}
    </div>
  );
};
