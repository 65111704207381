import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { Icon } from "../Icon/Icon";

import "./DragAndDropList.css";
import { noop } from "../../Utils/noop";

export type DragAndDropSortArgs<T> = {
  prevIndex: number;
  curIndex: number;
  item: T;
};
export type DragAndDropDeleteArgs<T> = { index: number; item: T };

type DragAndDropListType = <T extends Record<string, any>>(props: {
  items: T[];
  children: (
    data: { item: T; index: number },
    dragState: { isDragging: boolean }
  ) => JSX.Element;
  onSort?: (args: DragAndDropSortArgs<T>) => void;
  onDelete?: (args: DragAndDropDeleteArgs<T>) => void;
  readonly?: boolean;
}) => JSX.Element;

export const DragAndDropList: DragAndDropListType = ({
  items,
  children,
  onSort = noop,
  onDelete = noop,
  readonly = false,
}) => {
  const handleDragEnd = (result: DropResult) => {
    const item = items[result.source.index];

    onSort({
      prevIndex: result.source.index,
      curIndex: result.destination?.index || 0,
      item,
    });
  };

  const handleDeleteClick = (index: number, item: (typeof items)[0]) => {
    onDelete({ index, item });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(dropableProvided) => (
          <div
            ref={dropableProvided.innerRef}
            {...dropableProvided.droppableProps}
          >
            {items.map((item, i) => (
              <Draggable draggableId={`id-${i}`} index={i} key={`key-${i}`}>
                {(dragableProvided, dragableState) => (
                  <div
                    {...dragableProvided.draggableProps}
                    style={{
                      position: "relative",
                      ...dragableProvided.draggableProps.style,
                    }}
                    ref={dragableProvided.innerRef}
                  >
                    {readonly === false && (
                      <div
                        className="DragAndDropList__Handle"
                        {...dragableProvided.dragHandleProps}
                      >
                        <Icon name="sort" width={10} height={18} />
                      </div>
                    )}
                    {children(
                      { item, index: i },
                      { isDragging: dragableState.isDragging }
                    )}
                    {readonly === false && (
                      <div
                        className="DragAndDropList__Delete"
                        onClick={() => handleDeleteClick(i, item)}
                      >
                        <Icon name="deleteIcon" width={9} height={14} />
                      </div>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {dropableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
