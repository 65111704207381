import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";
import { usePropertiesNewMutation } from "../../Generated/graphql";

const PropertiesNew = () => {
  const history = useHistory();

  const [isValid, setIsValid] = useState(false);
  const [createProperty] = usePropertiesNewMutation();

  const [label, setLabel] = useState("");

  function save() {
    createProperty({
      variables: {
        label,
      },
    }).then(() => {
      history.push("/settings/properties");
    });
  }

  // Ensure we have a valid label
  useEffect(() => {
    setIsValid(label.length >= 3);
  }, [label]);

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Egenskaber",
      path: "/settings/properties",
    },
    {
      name: "Ny egenskab",
    },
  ];

  return (
    <SettingsForm
      onSave={save}
      isButtonActive={isValid}
      breadcrumbs={breadcrumbs}
      buttonLabel="Opret"
      theme="slim"
    >
      <FormElement label="Navn">
        <TextInput
          value={label}
          change={(e) => {
            setLabel(e.target.value);
          }}
        />
      </FormElement>
    </SettingsForm>
  );
};

const routed = withRouter(PropertiesNew);

export { routed as PropertiesNew };
