import { useState } from "react";

import "./OverviewCategories.css";
import { Icon } from "../Icon/Icon";
import { useReactiveVar } from "@apollo/client";
import { selectedCategoryVar } from "../../App";
import { animated, useSpring } from "react-spring";
import { useCategoriesQuery } from "../../Generated/graphql";
import classNames from "classnames";

export const OverviewCategories = () => {
  const [showAll, setShowAll] = useState(false);
  const selectedCategory = useReactiveVar(selectedCategoryVar);
  const { data: categoriesData } = useCategoriesQuery();

  const { height } = useSpring({
    from: { height: 0 },
    to: { height: showAll ? "auto" : (32 + 1) * 8 },
  });

  return (
    <div className="OverviewCategories">
      <div className="OverviewCategories__title">Kategorier</div>
      {selectedCategory && (
        <div
          className="OverviewCategories__reset"
          onClick={() => {
            selectedCategoryVar(null);
          }}
        >
          <div className="OverviewCategories__reset-icon">
            <Icon name="resetNormal" width={20} />
          </div>
          <div className="OverviewCategories__reset-text">Nulstil</div>
        </div>
      )}
      <animated.div className="OverviewCategories__buttons" style={{ height }}>
        {categoriesData &&
          categoriesData.categories.map((category) => (
            <div
              key={category}
              className={classNames("OverviewCategories__category", {
                "OverviewCategories__category--selected":
                  category === selectedCategory,
              })}
              onClick={() => {
                selectedCategoryVar(
                  selectedCategory === category ? null : category
                );
              }}
            >
              {category}
            </div>
          ))}
      </animated.div>
      <div
        className="OverviewCategories__showMore"
        onClick={() => setShowAll(!showAll)}
      >
        <div
          className="OverviewCategories__showMore-icon"
          style={{ transform: showAll ? "rotate(180deg)" : "rotate(0)" }}
        >
          <Icon name="arrowDown" width={10} height={20} />
        </div>
        <div className="OverviewCategories__showMore-text">
          {showAll ? "Skjul" : "Vis flere"}
        </div>
      </div>
    </div>
  );
};
