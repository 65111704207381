import React, { createContext, useReducer, useContext, ReactNode } from "react";

// Define the initial state and the reducer function
interface LightboxState {
  isOpen: boolean;
  imageUrl: string;
}

type LightboxAction =
  | { type: "OPEN_POPUP"; imageUrl: string }
  | { type: "CLOSE_POPUP" };

const initialState: LightboxState = {
  isOpen: false,
  imageUrl: "",
};

function reducer(state: LightboxState, action: LightboxAction): LightboxState {
  switch (action.type) {
    case "OPEN_POPUP":
      return { ...state, isOpen: true, imageUrl: action.imageUrl };
    case "CLOSE_POPUP":
      return { ...state, isOpen: false, imageUrl: "" };
    default:
      throw new Error();
  }
}

// Create the contexts for the state and the dispatch function
export const LightboxContext = createContext<LightboxState | undefined>(
  undefined
);
export const LightboxDispatchContext = createContext<
  ((action: LightboxAction) => void) | undefined
>(undefined);

// Combine the context and dispatch providers into a single provider component
export const LightboxProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LightboxContext.Provider value={state}>
      <LightboxDispatchContext.Provider value={dispatch}>
        {children}
      </LightboxDispatchContext.Provider>
    </LightboxContext.Provider>
  );
};

// Export custom hooks to read the context
export function useLightbox(): LightboxState {
  const context = useContext(LightboxContext);
  if (context === undefined) {
    throw new Error("useLightbox must be used within a LightboxProvider");
  }
  return context;
}

export function useLightboxDispatch(): (action: LightboxAction) => void {
  const context = useContext(LightboxDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLightboxDispatch must be used within a LightboxProvider"
    );
  }
  return context;
}
