import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  makeVar,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import * as moment from "moment-timezone";
import "moment/locale/da";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { Authorized } from "./Components/Authorized/Authorized";
import { Login } from "./Containers/Login/Login";
import { Main } from "./Containers/Main/Main";
import { Settings } from "./Containers/Settings/Settings";
import { config } from "./config";

moment.locale("da");

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.api.endpoint,
  })
);

const httpLink = new HttpLink({
  uri: config.api.endpoint,
  credentials: "include",
  fetch,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ["categories", "productNumbers", "searchTerm"],

            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              const joined = new Set();
              [...existing, ...incoming].forEach((value) => joined.add(value));
              return Array.from(joined);
            },
          },
        },
      },
    },
  }),
});

export type PercentageCompleteFilter = {
  type: "PERCENTAGE_COMPLETE";
  value: 25 | 50 | 75 | 100;
};

export type DataMissingFilter = {
  type: "DATA_MISSING";
  value:
    | "IMAGES"
    | "SHORT_DESCRIPTION"
    | "LONG_DESCRIPTION"
    | "META_DESCRIPTION"
    | "SPECIFICATIONS";
};

export type ProductStatusFilter = {
  type: "PRODUCT_STATUS";
  value: string;
};

export type ProductTypeFilter = {
  type: "PRODUCT_TYPE";
  value: "VariantGroup";
};

export type SelectedFilter =
  | PercentageCompleteFilter
  | DataMissingFilter
  | ProductStatusFilter
  | ProductTypeFilter;

export type SelectedFilters = SelectedFilter[];

// Var for the overview filters
export const selectedFiltersVar = makeVar<SelectedFilters>([]);

// Var for the overview category filter
export const selectedCategoryVar = makeVar<string | null>(null);

// Var for the overview search field
export const searchVar = makeVar("");

// Define a var to keep track of the category and selected product
// In the left bar navigation on edit pages
type EditNavigationVar = {
  selectedCategory: string;
  selectedProductNumber: string;
} | null;

export const editNavigationVar = makeVar<EditNavigationVar>(null);

export const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Switch>
        <Route
          path="/main"
          render={() => (
            <Authorized>
              <Main />
            </Authorized>
          )}
        />
        <Route
          path="/settings"
          render={() => (
            <Authorized>
              <Settings />
            </Authorized>
          )}
        />
        <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/" render={() => <Redirect to="/main" />} />
      </Switch>
    </BrowserRouter>
  </ApolloProvider>
);
