import { FC } from "react";

import "./SearchBar.css";
import { Icon } from "../Icon/Icon";

type Props = {
  value: string;
  changeValue: (value: string) => void;
};

export const SearchBar: FC<Props> = ({ value, changeValue }) => {
  return (
    <div className="SearchBar">
      <input
        className="SearchBar__input"
        placeholder="Søg produkter..."
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
      />
      <div
        className="SearchBar__icon u-cursor-pointer"
        onClick={() => changeValue(value)}
      >
        <Icon name="search" width={29} />
      </div>
    </div>
  );
};
