import { ChangeEvent, FC, FocusEvent, KeyboardEvent } from "react";

import "./TextInput.css";
import classNames from "classnames";
import { noop } from "../../Utils/noop";

type Props = {
  value: string;
  type?: string;
  styling?: string;
  readOnly?: boolean;
  placeholder?: string;
  autofocus?: boolean;
  change?: (e: ChangeEvent<HTMLInputElement>) => void;
  blur?: (e: FocusEvent<HTMLInputElement>) => void;
  keyPressed?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export const TextInput: FC<Props> = ({
  value,
  type = "text",
  styling = "default",
  readOnly = false,
  placeholder = "",
  autofocus = false,
  change = noop,
  blur = noop,
  keyPressed = noop,
}) => {
  return (
    <input
      className={classNames(`TextInput TextInput--${styling}`, {
        "TextInput--readonly": readOnly,
      })}
      placeholder={placeholder}
      value={value}
      onChange={change}
      type={type}
      disabled={readOnly}
      onBlur={blur}
      autoFocus={autofocus}
      onKeyPress={keyPressed}
    />
  );
};
