/**
 * A class that represents a percentage value
 */
export class Percentage {
  /* Return as decimal */
  getDecimal() {
    return this.value;
  }

  /* Return as percentage */
  getPercentage() {
    return this.value * 100;
  }

  /** Check if this percentage is in a given range */
  inRange(start: Percentage, end: Percentage, endInclusive = false) {
    // Get the decimal values for comparison
    const thisAsDecimal = this.getDecimal();
    const startAsDecimal = start.getDecimal();
    const endAsDecimal = end.getDecimal();

    // To be in range, the value should not be less than the start and not greater than the end
    const isNotLessThanStart = thisAsDecimal >= startAsDecimal;
    const isNotGreaterThanEnd = endInclusive
      ? thisAsDecimal <= endAsDecimal
      : thisAsDecimal < endAsDecimal;

    return isNotLessThanStart && isNotGreaterThanEnd;
  }

  constructor(
    /** The value in decimal */
    private readonly value: number
  ) {}

  /** Create from decimal */
  static fromDecimal(value: number) {
    return new Percentage(value);
  }

  static fromPercentage(value: number) {
    return new Percentage(value / 100);
  }

  /** A static creator method that calulates based on an amount of a given base */
  static fromAmountOf(amount: number, base: number) {
    return new Percentage(amount / base);
  }
}
